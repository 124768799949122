
/**
 * ** 🚨 DO NOT EDIT THIS FILE DIRECTLY!
 * If you want to make edits make them in each appropriate token JSON file.
 */

$emu-semantic-colors-none: rgba(0,0,0,0) !default;
$emu-semantic-colors-surface-light: #FFFFFF !default;
$emu-semantic-colors-surface-dark: #000000 !default;
$emu-semantic-colors-surface-modal-backdrop: rgba(0, 0, 0, 0.4) !default;
$emu-semantic-colors-actions-primary-light: #FF1D15 !default;
$emu-semantic-colors-actions-primary-dark: #CC0700 !default;
$emu-semantic-colors-actions-on-primary-light: #000000 !default;
$emu-semantic-colors-actions-on-primary-dark: #FFFFFF !default;
$emu-semantic-colors-text-light: #000000 !default;
$emu-semantic-colors-text-dark: #FFFFFF !default;
$emu-semantic-colors-foreground-light: #000000 !default;
$emu-semantic-colors-foreground-dark: #FFFFFF !default;
$emu-semantic-colors-primary-transparent: rgba(0,0,0,0) !default;
$emu-semantic-colors-primary-teal-100: #F9FEFF !default;
$emu-semantic-colors-primary-teal-200: #DCEFF0 !default;
$emu-semantic-colors-primary-teal-300: #00ACBB !default;
$emu-semantic-colors-primary-teal-400: #00A4B3 !default;
$emu-semantic-colors-primary-teal-500: #0096A3 !default;
$emu-semantic-colors-primary-white-solid: #FFFFFF !default;
$emu-semantic-colors-primary-white-header: rgba(255, 255, 255 ,0.9) !default;
$emu-semantic-colors-primary-black: #000000 !default;
$emu-semantic-colors-primary-bone: #F6F1EE !default;
$emu-semantic-colors-secondary-grey-100: #D8D8D8 !default;
$emu-semantic-colors-secondary-grey-200: #979797 !default;
$emu-semantic-colors-secondary-grey-300: #484848 !default;
$emu-semantic-colors-secondary-grey-400: rgba(238, 238, 238, 0) !default;
$emu-semantic-font-weight-bold: 700 !default;
$emu-semantic-font-weight-light: 300 !default;
$emu-semantic-font-weight-black: 900 !default;
$emu-semantic-font-weight-regular: 400 !default;
$emu-semantic-font-families-heading: Helvetica Now Text W01 Bold !default;
$emu-semantic-font-families-body: Helvetica Now Text W01 Regular !default;
$emu-semantic-font-families-light: Helvetica Now Text W01 Light !default;
$emu-semantic-font-families-italic: Helvetica Now Text W01 Italic !default;
$emu-semantic-font-families-medium: Helvetica Now Text W01 Medium !default;
$emu-semantic-font-families-juana-light: Juana W05 Light !default;
$emu-semantic-font-families-juana-regular: Juana W05 Regular !default;
$emu-semantic-spacing-none: 0px !default;
$emu-semantic-spacing-horizontal: 8px !default;
$emu-semantic-spacing-vertical: 8px !default;
$emu-semantic-spacing-one-line-height-wide: 44px !default;
$emu-semantic-spacing-one-line-height-narrow: 30px !default;
$emu-semantic-font-sizes-narrow-xxs: 12px !default;
$emu-semantic-font-sizes-narrow-xs: 18px !default;
$emu-semantic-font-sizes-narrow-small: 20px !default;
$emu-semantic-font-sizes-narrow-medium: 22px !default;
$emu-semantic-font-sizes-narrow-large: 25px !default;
$emu-semantic-font-sizes-narrow-xl: 28px !default;
$emu-semantic-font-sizes-narrow-xxl: 30px !default;
$emu-semantic-font-sizes-narrow-xxxl: 35px !default;
$emu-semantic-font-sizes-wide-xs: 14px !default;
$emu-semantic-font-sizes-wide-small: 16px !default;
$emu-semantic-font-sizes-wide-nav: 20px !default;
$emu-semantic-font-sizes-wide-medium: 40px !default;
$emu-semantic-font-sizes-wide-large: 50px !default;
$emu-semantic-font-sizes-wide-xl: 55px !default;
$emu-semantic-font-sizes-wide-xxl: 70px !default;
$emu-semantic-font-sizes-wide-xxxl: 63.96px !default;
$emu-semantic-line-heights-narrow-xxs: 14px !default;
$emu-semantic-line-heights-narrow-xs: 17px !default;
$emu-semantic-line-heights-narrow-small: 25px !default;
$emu-semantic-line-heights-narrow-medium: 30px !default;
$emu-semantic-line-heights-narrow-large: 35px !default;
$emu-semantic-line-heights-narrow-xl: 38px !default;
$emu-semantic-line-heights-narrow-xxl: 40px !default;
$emu-semantic-line-heights-narrow-xxxl: 45px !default;
$emu-semantic-line-heights-wide-nav: 15px !default;
$emu-semantic-line-heights-wide-top-navigation: 17px !default;
$emu-semantic-line-heights-wide-xs: 18px !default;
$emu-semantic-line-heights-wide-small: 20px !default;
$emu-semantic-line-heights-wide-medium: 44px !default;
$emu-semantic-line-heights-wide-large: 50px !default;
$emu-semantic-line-heights-wide-xl: 55px !default;
$emu-semantic-line-heights-wide-xxl: 65px !default;
$emu-semantic-line-heights-wide-xxxl: 66px !default;
$emu-semantic-border-radius-xs: 20px !default;
$emu-semantic-border-radius-small: 21px !default;
$emu-semantic-border-radius-medium: 22.5px !default;
$emu-semantic-border-radius-large: 26px !default;
$emu-semantic-border-radius-xl: 32px !default;
$emu-semantic-border-radius-round: 100px !default;
$emu-semantic-border-radius-none: 0px !default;
$emu-semantic-border-width-thin: 1px !default;
$emu-semantic-border-width-medium: 2px !default;
$emu-semantic-border-width-thick: 4px !default;
$emu-semantic-border-width-none: 0px !default;
$emu-semantic-outline-width-thin: thin !default;
$emu-semantic-outline-width-medium: medium !default;
$emu-semantic-outline-width-thick: thick !default;
$emu-semantic-outline-width-unset: unset !default;
$emu-semantic-sizing-none: 0px !default;
$emu-semantic-sizing-xxs: 1px !default;
$emu-semantic-sizing-xs: 8px !default;
$emu-semantic-sizing-small: 16px !default;
$emu-semantic-sizing-medium: 32px !default;
$emu-semantic-sizing-large: 64px !default;
$emu-semantic-sizing-xl: 128px !default;
$emu-semantic-sizing-xxl: 256px !default;
$emu-semantic-sizing-breakpoints-small: 320px !default;
$emu-semantic-sizing-breakpoints-medium: 768px !default;
$emu-semantic-sizing-breakpoints-large: 1024px !default;
$emu-semantic-sizing-breakpoints-x-large: 1201px !default;
$emu-semantic-sizing-breakpoints-xx-large: 1441px !default;
$emu-semantic-sizing-one-line-height-wide: 44px !default;
$emu-semantic-sizing-one-line-height-narrow: 30px !default;
$emu-semantic-typography-narrow-headings-headline-h1: 300 35px/45px Juana W05 Light !default;
$emu-semantic-typography-narrow-headings-headline-h2: 300 28px/38px Juana W05 Light !default;
$emu-semantic-typography-narrow-headings-headline-h3: 300 30px/40px Juana W05 Light !default;
$emu-semantic-typography-narrow-headings-headline-h4: 700 25px/30px Helvetica Now Text W01 Bold !default;
$emu-semantic-typography-narrow-headings-headline-h4-teal: 400 25px/30px Helvetica Now Text W01 Regular !default;
$emu-semantic-typography-narrow-headings-q-a-headline: 400 20px/25px Helvetica Now Text W01 Regular !default;
$emu-semantic-typography-narrow-headings-eyebrow: 400 25px/35px Helvetica Now Text W01 Regular !default;
$emu-semantic-typography-narrow-headings-xxxl: 400 47.97px/51px Arial !default;
$emu-semantic-typography-narrow-headings-xxl: 400 33.93px/34px Arial !default;
$emu-semantic-typography-narrow-headings-xl: 400 23.99px/34px Arial !default;
$emu-semantic-typography-narrow-headings-large: 400 16.97px/17px Arial !default;
$emu-semantic-typography-narrow-headings-medium: 700 12px/17px Helvetica Now Text W01 Bold !default;
$emu-semantic-typography-narrow-body-intro-large: 300 22px/30px Helvetica Now Text W01 Light !default;
$emu-semantic-typography-narrow-body-intro-small: 300 20px/30px Helvetica Now Text W01 Light !default;
$emu-semantic-typography-narrow-body-regular: 300 18px/25px Helvetica Now Text W01 Light !default;
$emu-semantic-typography-narrow-body-small: 300 14px/17px Helvetica Now Text W01 Regular !default;
$emu-semantic-typography-narrow-body-footnotes: 300 12px/14px Helvetica Now Text W01 Light !default;
$emu-semantic-typography-narrow-body-footer: 400 18px/25px Helvetica Now Text W01 Regular !default;
$emu-semantic-typography-wide-headings-headline-h1: 300 55px/65px Juana W05 Light !default;
$emu-semantic-typography-wide-headings-headline-h2: 400 40px/55px Juana W05 Regular !default;
$emu-semantic-typography-wide-headings-headline-h3: 300 35px/50px Juana W05 Light !default;
$emu-semantic-typography-wide-headings-headline-h4: 700 20px/30px Helvetica Now Text W01 Bold !default;
$emu-semantic-typography-wide-headings-headline-h4-teal: 400 25px/30px Helvetica Now Text W01 Medium !default;
$emu-semantic-typography-wide-headings-q-a-headline: 400 25px/30px Helvetica Now Text W01 Regular !default;
$emu-semantic-typography-wide-headings-eyebrow: 400 30px/44px Helvetica Now Text W01 Regular !default;
$emu-semantic-typography-wide-headings-main-nav: 400 20px/15px Helvetica Now Text W01 Regular !default;
$emu-semantic-typography-wide-headings-xxxl: 400 63.96px/66px Arial !default;
$emu-semantic-typography-wide-headings-xxl: 400 45.23px/44px Arial !default;
$emu-semantic-typography-wide-headings-xl: 400 31.99px/44px Arial !default;
$emu-semantic-typography-wide-headings-large: 400 22.62px/22px Arial !default;
$emu-semantic-typography-wide-headings-medium: 700 16px/22px Arial !default;
$emu-semantic-typography-wide-body-intro-large: 300 35px/45px Helvetica Now Text W01 Light !default;
$emu-semantic-typography-wide-body-intro-small: 300 25px/35px Helvetica Now Text W01 Light !default;
$emu-semantic-typography-wide-body-regular: 300 20px/30px Helvetica Now Text W01 Light !default;
$emu-semantic-typography-wide-body-small: 300 16px/18px Helvetica Now Text W01 Light !default;
$emu-semantic-typography-wide-body-footnotes: 300 14px/20px Helvetica Now Text W01 Light !default;
$emu-semantic-typography-wide-body-top-navigation: 400 12px/17px Helvetica Now Text W01 Regular !default;

$semantic: (
  'semantic': (
    'colors': (
      'none': $emu-semantic-colors-none,
      'surface': (
        'light': $emu-semantic-colors-surface-light,
        'dark': $emu-semantic-colors-surface-dark,
        'modal-backdrop': $emu-semantic-colors-surface-modal-backdrop
      ),
      'actions': (
        'primary': (
          'light': $emu-semantic-colors-actions-primary-light,
          'dark': $emu-semantic-colors-actions-primary-dark
        ),
        'on-primary': (
          'light': $emu-semantic-colors-actions-on-primary-light,
          'dark': $emu-semantic-colors-actions-on-primary-dark
        )
      ),
      'text': (
        'light': $emu-semantic-colors-text-light,
        'dark': $emu-semantic-colors-text-dark
      ),
      'foreground': (
        'light': $emu-semantic-colors-foreground-light,
        'dark': $emu-semantic-colors-foreground-dark
      ),
      'primary': (
        'transparent': $emu-semantic-colors-primary-transparent,
        'teal': (
          '100': $emu-semantic-colors-primary-teal-100,
          '200': $emu-semantic-colors-primary-teal-200,
          '300': $emu-semantic-colors-primary-teal-300,
          '400': $emu-semantic-colors-primary-teal-400,
          '500': $emu-semantic-colors-primary-teal-500
        ),
        'white': (
          'solid': $emu-semantic-colors-primary-white-solid,
          'header': $emu-semantic-colors-primary-white-header
        ),
        'black': $emu-semantic-colors-primary-black,
        'bone': $emu-semantic-colors-primary-bone
      ),
      'secondary': (
        'grey': (
          '100': $emu-semantic-colors-secondary-grey-100,
          '200': $emu-semantic-colors-secondary-grey-200,
          '300': $emu-semantic-colors-secondary-grey-300,
          '400': $emu-semantic-colors-secondary-grey-400
        )
      )
    ),
    'fontWeight': (
      'bold': $emu-semantic-font-weight-bold,
      'light': $emu-semantic-font-weight-light,
      'black': $emu-semantic-font-weight-black,
      'regular': $emu-semantic-font-weight-regular
    ),
    'fontFamilies': (
      'heading': $emu-semantic-font-families-heading,
      'body': $emu-semantic-font-families-body,
      'light': $emu-semantic-font-families-light,
      'italic': $emu-semantic-font-families-italic,
      'medium': $emu-semantic-font-families-medium,
      'juanaLight': $emu-semantic-font-families-juana-light,
      'juanaRegular': $emu-semantic-font-families-juana-regular
    ),
    'spacing': (
      'none': $emu-semantic-spacing-none,
      'horizontal': $emu-semantic-spacing-horizontal,
      'vertical': $emu-semantic-spacing-vertical,
      'oneLineHeight': (
        'wide': $emu-semantic-spacing-one-line-height-wide,
        'narrow': $emu-semantic-spacing-one-line-height-narrow
      )
    ),
    'fontSizes': (
      'narrow': (
        'xxs': $emu-semantic-font-sizes-narrow-xxs,
        'xs': $emu-semantic-font-sizes-narrow-xs,
        'small': $emu-semantic-font-sizes-narrow-small,
        'medium': $emu-semantic-font-sizes-narrow-medium,
        'large': $emu-semantic-font-sizes-narrow-large,
        'xl': $emu-semantic-font-sizes-narrow-xl,
        'xxl': $emu-semantic-font-sizes-narrow-xxl,
        'xxxl': $emu-semantic-font-sizes-narrow-xxxl
      ),
      'wide': (
        'xs': $emu-semantic-font-sizes-wide-xs,
        'small': $emu-semantic-font-sizes-wide-small,
        'nav': $emu-semantic-font-sizes-wide-nav,
        'medium': $emu-semantic-font-sizes-wide-medium,
        'large': $emu-semantic-font-sizes-wide-large,
        'xl': $emu-semantic-font-sizes-wide-xl,
        'xxl': $emu-semantic-font-sizes-wide-xxl,
        'xxxl': $emu-semantic-font-sizes-wide-xxxl
      )
    ),
    'lineHeights': (
      'narrow': (
        'xxs': $emu-semantic-line-heights-narrow-xxs,
        'xs': $emu-semantic-line-heights-narrow-xs,
        'small': $emu-semantic-line-heights-narrow-small,
        'medium': $emu-semantic-line-heights-narrow-medium,
        'large': $emu-semantic-line-heights-narrow-large,
        'xl': $emu-semantic-line-heights-narrow-xl,
        'xxl': $emu-semantic-line-heights-narrow-xxl,
        'xxxl': $emu-semantic-line-heights-narrow-xxxl
      ),
      'wide': (
        'nav': $emu-semantic-line-heights-wide-nav,
        'top-navigation': $emu-semantic-line-heights-wide-top-navigation,
        'xs': $emu-semantic-line-heights-wide-xs,
        'small': $emu-semantic-line-heights-wide-small,
        'medium': $emu-semantic-line-heights-wide-medium,
        'large': $emu-semantic-line-heights-wide-large,
        'xl': $emu-semantic-line-heights-wide-xl,
        'xxl': $emu-semantic-line-heights-wide-xxl,
        'xxxl': $emu-semantic-line-heights-wide-xxxl
      )
    ),
    'borderRadius': (
      'xs': $emu-semantic-border-radius-xs,
      'small': $emu-semantic-border-radius-small,
      'medium': $emu-semantic-border-radius-medium,
      'large': $emu-semantic-border-radius-large,
      'xl': $emu-semantic-border-radius-xl,
      'round': $emu-semantic-border-radius-round,
      'none': $emu-semantic-border-radius-none
    ),
    'borderWidth': (
      'thin': $emu-semantic-border-width-thin,
      'medium': $emu-semantic-border-width-medium,
      'thick': $emu-semantic-border-width-thick,
      'none': $emu-semantic-border-width-none
    ),
    'outlineWidth': (
      'thin': $emu-semantic-outline-width-thin,
      'medium': $emu-semantic-outline-width-medium,
      'thick': $emu-semantic-outline-width-thick,
      'unset': $emu-semantic-outline-width-unset
    ),
    'sizing': (
      'none': $emu-semantic-sizing-none,
      'xxs': $emu-semantic-sizing-xxs,
      'xs': $emu-semantic-sizing-xs,
      'small': $emu-semantic-sizing-small,
      'medium': $emu-semantic-sizing-medium,
      'large': $emu-semantic-sizing-large,
      'xl': $emu-semantic-sizing-xl,
      'xxl': $emu-semantic-sizing-xxl,
      'breakpoints': (
        'small': $emu-semantic-sizing-breakpoints-small,
        'medium': $emu-semantic-sizing-breakpoints-medium,
        'large': $emu-semantic-sizing-breakpoints-large,
        'x-large': $emu-semantic-sizing-breakpoints-x-large,
        'xx-large': $emu-semantic-sizing-breakpoints-xx-large
      ),
      'oneLineHeight': (
        'wide': $emu-semantic-sizing-one-line-height-wide,
        'narrow': $emu-semantic-sizing-one-line-height-narrow
      )
    ),
    'typography': (
      'narrow': (
        'headings': (
          'headlineH1': $emu-semantic-typography-narrow-headings-headline-h1,
          'headlineH2': $emu-semantic-typography-narrow-headings-headline-h2,
          'headlineH3': $emu-semantic-typography-narrow-headings-headline-h3,
          'headlineH4': $emu-semantic-typography-narrow-headings-headline-h4,
          'headlineH4-teal': $emu-semantic-typography-narrow-headings-headline-h4-teal,
          'Q&AHeadline': $emu-semantic-typography-narrow-headings-q-a-headline,
          'eyebrow': $emu-semantic-typography-narrow-headings-eyebrow,
          'xxxl': $emu-semantic-typography-narrow-headings-xxxl,
          'xxl': $emu-semantic-typography-narrow-headings-xxl,
          'xl': $emu-semantic-typography-narrow-headings-xl,
          'large': $emu-semantic-typography-narrow-headings-large,
          'medium': $emu-semantic-typography-narrow-headings-medium
        ),
        'body': (
          'introLarge': $emu-semantic-typography-narrow-body-intro-large,
          'introSmall': $emu-semantic-typography-narrow-body-intro-small,
          'regular': $emu-semantic-typography-narrow-body-regular,
          'small': $emu-semantic-typography-narrow-body-small,
          'footnotes': $emu-semantic-typography-narrow-body-footnotes,
          'footer': $emu-semantic-typography-narrow-body-footer
        )
      ),
      'wide': (
        'headings': (
          'headlineH1': $emu-semantic-typography-wide-headings-headline-h1,
          'headlineH2': $emu-semantic-typography-wide-headings-headline-h2,
          'headlineH3': $emu-semantic-typography-wide-headings-headline-h3,
          'headlineH4': $emu-semantic-typography-wide-headings-headline-h4,
          'headlineH4-teal': $emu-semantic-typography-wide-headings-headline-h4-teal,
          'Q&AHeadline': $emu-semantic-typography-wide-headings-q-a-headline,
          'eyebrow': $emu-semantic-typography-wide-headings-eyebrow,
          'main-nav': $emu-semantic-typography-wide-headings-main-nav,
          'xxxl': $emu-semantic-typography-wide-headings-xxxl,
          'xxl': $emu-semantic-typography-wide-headings-xxl,
          'xl': $emu-semantic-typography-wide-headings-xl,
          'large': $emu-semantic-typography-wide-headings-large,
          'medium': $emu-semantic-typography-wide-headings-medium
        ),
        'body': (
          'introLarge': $emu-semantic-typography-wide-body-intro-large,
          'introSmall': $emu-semantic-typography-wide-body-intro-small,
          'regular': $emu-semantic-typography-wide-body-regular,
          'small': $emu-semantic-typography-wide-body-small,
          'footnotes': $emu-semantic-typography-wide-body-footnotes,
          'top-navigation': $emu-semantic-typography-wide-body-top-navigation
        )
      )
    )
  )
);
