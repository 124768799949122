[data-component='container-isi-v2'] {
  &.isi-scrollable .isi-container {
    z-index: var(--emu-common-other-z-index-isi);
  }
  .aaaem-isi-container {
    ul,
    p {
      font: var(--emu-semantic-typography-narrow-body-footnotes);

      b {
        color: var(--emu-semantic-colors-primary-teal-400);
        font-family: var(--emu-semantic-font-families-heading);
        font-size: var(--emu-semantic-font-sizes-wide-xs);
        line-height: var(--emu-semantic-line-heights-narrow-xs);

        @include mq('large') {
          font-size: var(--emu-semantic-font-sizes-wide-small);
          line-height: var(--emu-common-line-heights-narrow-large);
        }
      }

      @include mq('large') {
        font: var(--emu-semantic-typography-wide-body-small);
      }
    }

    .aaaem-isi-container__isi-content {
      .text {
        width: calc(100% - 15px);

        @include mq('large') {
          width: calc(100% - 25px);
        }
      }

      &.js-expand {
        max-width: var(--emu-component-containers-container-max-width);
        margin: auto;
      }
    }

    .aaaem-isi-banner {
      max-width: var(--emu-component-containers-container-max-width);
      margin-top: var(--emu-common-spacing-none);
      margin-right: auto;
      margin-bottom: var(--emu-common-spacing-none);
      margin-left: auto;
      justify-content: right;
      position: relative;
    }

    &__banner-content {
      max-height: 95px;
      padding-top: 15px;

      --mask: linear-gradient(
        360deg,
        rgba(117, 36, 36, 0) 20%,
        var(--emu-semantic-colors-primary-bone) 0%
      );
      -webkit-mask: var(--mask);
      mask: var(--mask);

      @include mq('medium') {
        --mask: linear-gradient(
          360deg,
          var(--emu-semantic-colors-secondary-grey-400) 32%,
          var(--emu-semantic-colors-primary-bone) 0%
        );
      }

      @include mq('large') {
        --mask: linear-gradient(
          360deg,
          var(--emu-semantic-colors-secondary-grey-400) 0%,
          var(--emu-semantic-colors-primary-bone) 60%
        );
        max-height: 105px;
        padding-top: var(--emu-common-spacing-none);
      }

      p {
        margin-bottom: var(--emu-common-spacing-xs);
        margin-top: var(--emu-common-spacing-xs);

        .emphasis {
          @include mq('large') {
            display: none;
          }
        }
      }

      @include mq('large') {
        display: flex;

        p {
          margin-bottom: 12px;
          margin-top: 12px;
        }
      }

      > div:first-child {
        @include mq('large') {
          width: 29%;
          margin-right: 20px;
          padding-right: var(--emu-common-spacing-xs);
        }
        p:last-child,
        p:nth-child(3) {
          @include mq('large') {
            margin-top: var(--emu-common-spacing-none);
          }
        }
        p:nth-child(3) {
          margin-bottom: var(--emu-common-spacing-none);
        }
      }

      > div:nth-child(2) {
        @include mq('large') {
          width: 70%; //needed to match figma
        }
        p:last-child {
          @include mq('large') {
            margin-top: var(--emu-common-spacing-none);
          }
        }
        p:first-child {
          @include mq('large') {
            margin-bottom: var(--emu-common-spacing-none);
          }
        }
      }
    }

    &__button-wrapper {
      @include mq('large') {
        position: absolute;
      }

      button {
        padding-left: 15px;
        padding-right: 15px;

        .cmp-button__text {
          display: none;
        }

        &.js-toggle-on {
          position: absolute;
          right: var(--emu-common-spacing-medium);
          top: var(--emu-common-spacing-none);
          z-index: var(--emu-component-containers-isi-z-index);

          @include mq('large') {
            position: static;
            right: 65px;
          }
        }
      }
    }

    .aaaem-button {
      &.plus-minus-icon {
        .aaaem-button__icon {
          height: 20px;

          &::before,
          &::after {
            background-color: var(--emu-semantic-colors-primary-teal-400);
          }
        }
      }

      &:focus {
        outline: none;
      }
    }
  }

  .aaaem-isi-container__isi-content {
    > div {
      margin: auto;
      max-width: var(--emu-component-containers-container-max-width);

      p,
      ul {
        font: var(--emu-semantic-typography-narrow-body-footnotes);
        line-height: 17px;

        @include mq('large') {
          font: var(--emu-semantic-typography-wide-body-small);
          line-height: 18px;
          width: 95%;
          margin-left: auto;
          margin-right: auto;
        }
        &:first-child {
          margin-top: var(--emu-common-spacing-none);
        }

        a,
        a:hover,
        a:active,
        a:visited {
          text-decoration: none;
          color: var(--emu-semantic-colors-primary-teal-400);
        }
      }

      ul {
        padding-left: 14px;

        @include mq('large') {
          padding-left: 22px;
        }

        &:nth-child(5),
        &:nth-child(9) {
          margin-top: -10px; //needed to match figma
        }
      }

      b {
        color: var(--emu-semantic-colors-primary-teal-400);
        font-family: var(--emu-semantic-font-families-heading);
        font-size: var(--emu-semantic-font-sizes-wide-xs);

        @include mq('large') {
          font-size: var(--emu-semantic-font-sizes-wide-small);
        }
      }
    }
  }

  [data-ref='content-footer'] {
    border-top: var(--emu-common-border-width-thick) solid
      var(--emu-semantic-colors-primary-teal-400);
  }

  .aaaem-isi-holder {
    .text {
      ul {
        padding-inline-start: 14px;
      }
    }

    > .aaaem-isi-container__isi-content {
      .text {
        max-width: var(--emu-component-containers-container-max-width);
        margin: auto;
      }
    }
  }
}
