#error-handler {
  .error {
    &__wrapper {
      padding-top: 90px;
      padding-left: 26px;
      padding-right: 26px;

      @include mq('large') {
        padding-top: 150px;
        padding-left: 95px;
      }

      h1 {
        display: inline-block;
        margin-bottom: 45px;
        font: var(--emu-semantic-typography-narrow-headings-eyebrow);

        @include mq('large') {
          font: var(--emu-semantic-typography-wide-headings-eyebrow);
        }

        &::after {
          content: '';
          width: 100%;
          background: var(--emu-semantic-colors-primary-teal-300);
          height: var(--emu-semantic-border-width-thick);
          border-radius: var(--emu-semantic-border-radius-round);
          display: flex;
          position: relative;
        }
      }

      a {
        display: inline-block;
        width: auto;
      }
    }

    &__text {
      &--secondary {
        a {
          &,
          &:hover,
          &:active,
          &:focus {
            color: var(--emu-semantic-colors-primary-teal-500);
            text-decoration: none;
          }
        }
      }
    }
  }
}
