.aaaem-accordion {
  width: 87%; // To match the design.
  margin: auto;

  &::after {
    content: '';
    border-bottom: var(--emu-common-border-width-thin) solid
      var(--emu-component-lists-accordion-item-color-border-bottom-closed-light);
    display: block;
  }

  @include mq('x-large') {
    width: 100%;
    max-width: var(--emu-component-containers-container-max-width);
    padding-top: var(--emu-common-spacing-none);
    padding-right: 96px;
    padding-bottom: var(--emu-common-spacing-none);
    padding-left: 96px;
  }

  &__header {
    border: none;
    border-top: var(--emu-common-border-width-thin) solid
      var(--emu-component-lists-accordion-item-color-border-bottom-closed-light);
    transition: all 0.2s ease-in-out;
  }

  &__item {
    &:first-child {
      .aaaem-accordion__header {
        border: none;
      }
    }

    .-ba-is-active {
      padding-bottom: 5px;
    }
  }

  &__title {
    font: var(--emu-semantic-typography-narrow-headings-q-a-headline);
    color: var(--emu-semantic-colors-primary-teal-300);

    @include mq('x-large') {
      font: var(--emu-semantic-typography-wide-headings-q-a-headline);
    }
  }

  dt {
    .text {
      font: var(--emu-semantic-typography-narrow-body-regular);

      @include mq('x-large') {
        font: var(--emu-semantic-typography-wide-body-regular);
      }
    }
  }

  &__panel {
    border: none;
    padding-left: var(--emu-common-spacing-large);

    @include mq('large') {
      padding-left: 54px;
    }

    .aaaem-text p {
      margin-top: var(--emu-common-spacing-none);
      margin-bottom: var(--emu-common-spacing-none);
      padding-bottom: 30px;
    }
  }

  &__icon {
    align-self: flex-start;
    padding-top: var(--emu-common-spacing-xs);
  }
}

.accordion.panelcontainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 96px; // To match the design.
  width: 100%;
  align-items: center;
  list-style-type: none;
  counter-reset: css-counter 0;

  @include mq('large') {
    margin-top: 60px; // To match the design.
    margin-bottom: 148px; // As per design.
  }

  dt {
    counter-increment: css-counter 1;

    button::before {
      content: counter(css-counter);
      display: inline-block;
      margin-right: 12px;
      margin-top: var(--emu-common-spacing-small);
      font-family: var(--emu-semantic-font-families-light);
      font-size: 14px;
      line-height: 1;

      @include mq('large') {
        font-size: 20px;
        margin-right: var(--emu-common-spacing-large);
        margin-top: 6px;
      }
    }

    &:nth-child(-n + 18) button::before {
      content: '0' counter(css-counter);
    }
  }
}
