.comparisonslider {
  .emu-comparison-slider {
    &__slider {
      margin: inherit;
    }

    img-comparison-slider {
      width: 100%;
      --handle-position-start: 100%; //need to overwrite
      margin: var(--emu-common-sizing-none);
    }

    &__handle {
      position: relative;
      background: var(--emu-semantic-colors-primary-teal-400);
      padding-top: var(--emu-common-spacing-none);
      padding-bottom: var(--emu-common-spacing-none);
      padding-left: 5px;
      padding-right: 5px;
      height: 24px; //to match figma
      left: 0;
      top: -12px;
    }
    &__text {
      font-size: 14px;
      letter-spacing: 0.2px;
      color: var(--emu-common-colors-white);
    }

    &__arrow {
      border-top: 6px solid var(--emu-common-colors-transparent);
      border-bottom: 6px solid var(--emu-common-colors-transparent);
      transform: none;
      font-family: var(--emu-semantic-font-families-light);
      width: var(--emu-common-sizing-none);
      height: var(--emu-common-sizing-none);

      &--left {
        border-left: none;
        border-right: 11px solid var(--emu-common-colors-white);
        margin-right: 10px;
      }

      &--right {
        margin-left: 10px;
        border-left: 11px solid var(--emu-common-colors-white);
        border-right: none;
      }
    }
  }
}

.divider {
  &::after {
    border-left-color: var(--emu-common-colors-white);
    border-left-width: var(--emu-common-border-width-medium);
  }
}
