/** Slider Carousel Start **/
.slider-carousel {
  position: relative;
  overflow: hidden;

  > div {
    margin-bottom: 105px;

    @include mq('large') {
      margin-bottom: 175px;
    }
  }

  &__main {
    margin: var(--emu-common-spacing-none);
  }

  .aaaem-carousel__content {
    margin-left: auto;
    margin-right: auto;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    z-index: var(--emu-common-other-z-index-layer);
    display: none;
  }

  &::before {
    background-color: var(--emu-common-colors-white);
    width: 100%;
    height: 100%;
  }

  &::after {
    width: 60px; // needed to show the loader properly
    height: 60px; // needed to show the loader properly
    background-image: url('../../assets/images/loading.gif');
    background-size: cover;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &.loading {
    &::after,
    &::before {
      display: block;
    }
  }

  // the slider itself
  // increasing specificity here
  .slider-carousel__comp-slider {
    margin-bottom: var(--emu-common-spacing-small);
    display: none;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;

    img {
      width: 100%;
      object-fit: contain;
    }

    &--zoom-out {
      width: 500px; // value deduced from live site
    }

    &--zoom-out-small-active {
      width: 385px; // value deduced from live site
      max-height: 249.115px; // value deduced from live site
      margin-bottom: 15px;
    }

    &--zoom-in {
      width: 500px; // value deduced from live site
    }

    &--active {
      display: flex;
    }
  }

  &__ba[data-component='text'] {
    display: none;
    pointer-events: none;

    &.slider-carousel__ba--main {
      @include mq('large') {
        display: block;
        position: absolute;
        top: 48%;
        left: 5.8%; // values derived from live site
        width: 88.4%; // values derived from live site
      }
    }

    &.slider-carousel__ba--in-item {
      display: block;
      padding-left: 20px;
      padding-right: 20px;
      margin-bottom: 5px;

      @include mq('large') {
        padding: var(--emu-common-spacing-none);
        margin-bottom: var(--emu-common-spacing-none);
      }
    }

    ul {
      margin: var(--emu-common-spacing-none);
      padding: var(--emu-common-spacing-none);
      display: flex;
      list-style: none;
      justify-content: space-between;
      pointer-events: none;
      margin-left: auto;
      margin-right: auto;
      width: 100%;

      @include mq('large') {
        position: absolute;
        top: 45%;
      }

      li {
        margin: var(--emu-semantic-spacing-none);
        font-size: 14px;
        line-height: var(--emu-semantic-line-heights-narrow-medium);
        color: var(--emu-semantic-colors-primary-teal-400);
        font-family: var(--emu-semantic-font-families-medium);
        @include mq('large') {
          color: var(--emu-common-colors-black);
        }
      }
    }
  }

  &__item {
    &-inner {
      position: relative;

      @include mq('large') {
        margin-top: 15px;
      }
    }

    &-desc[data-component='text'] {
      padding-left: 10px;
      padding-right: 10px;
      text-align: center;

      @include mq('large') {
        position: absolute;
        top: 15%;
        right: 0;
        padding: var(--emu-common-spacing-none);
        max-width: 160px;
      }

      h3 {
        margin-top: var(--emu-semantic-spacing-none);
        margin-bottom: var(--emu-common-spacing-none);
        color: var(--emu-semantic-colors-primary-teal-500);
        font-family: var(--emu-semantic-font-families-body);
        font-size: var(--emu-semantic-font-sizes-narrow-small);
        line-height: var(--emu-semantic-line-heights-narrow-medium);
        text-align: center;
        font-weight: var(--emu-common-font-weight-black);

        @include mq('large') {
          text-align: end;
        }
      }

      p {
        margin-top: var(--emu-semantic-spacing-none);
        margin-bottom: 25px;
        font-size: 14px;
        line-height: var(--emu-common-line-heights-narrow-large);
        text-align: center;

        @include mq('large') {
          text-align: end;
          font-size: 16px;
        }
      }

      sup {
        position: relative;
        top: 5px;
        line-height: 0;
        font-size: inherit;

        @include mq('large') {
          top: 6px;
        }
      }
    }

    &-disclaimer[data-component='text'] {
      padding-left: 10px;
      padding-right: 10px;
      max-width: 620px; // value from live site
      margin-left: auto;
      margin-right: auto;

      p {
        max-width: 90%;
        margin-bottom: 18px;
        margin-top: var(--emu-common-spacing-small);
        font-weight: var(--emu-semantic-font-weight-500);
        line-height: 16px;
        font-size: var(--emu-common-font-sizes-narrow-medium);
        margin-left: auto;
        margin-right: auto;

        @include mq('large') {
          font-size: var(--emu-semantic-font-sizes-wide-xs);
          margin-bottom: var(--emu-common-spacing-large);
          line-height: var(--emu-semantic-line-heights-wide-small);
          max-width: none;
        }
      }

      sup {
        position: relative;
        top: 5px;
        line-height: 0;
        font-size: inherit;
      }
    }

    &-slider-container[data-component='container'] {
      &.aaaem-container {
        padding-left: 10px;
        padding-right: 10px;

        @include mq('large') {
          padding-left: var(--emu-common-spacing-none);
          padding-right: var(--emu-common-spacing-none);
        }
      }
    }
  }

  &__control {
    &[data-component='button'] {
      width: 34px; // to match live site
      height: 35px; // to match live site
      padding: var(--emu-common-spacing-none);
      -webkit-tap-highlight-color: var(--emu-common-colors-transparent);

      &,
      &:hover,
      &:active,
      &:focus {
        border: none;
        background-color: var(--emu-common-colors-transparent);
      }
    }

    .cmp-button__text {
      display: none;
    }

    > span {
      background-image: url('../../assets/images/zoom-minus-green.png');
      background-repeat: no-repeat;
      background-size: cover;
      width: inherit;
      height: inherit;
      display: block;
    }

    &--zoom-in {
      > span {
        background-image: url('../../assets/images/zoom-plus-green.png');
      }
    }

    &--zoom-out-small {
      [disabled] {
        > span {
          background-color: var(--emu-semantic-colors-primary-teal-400);
        }
      }

      > span {
        background-image: none;
        width: 15px; // needed to match the design
        height: 15px; // needed to match the design
        background-color: var(--emu-semantic-colors-primary-teal-400);
        border-radius: 50%;
        margin-top: var(--emu-common-spacing-xs);

        @include mq('large') {
          margin-top: 5px;
        }
      }
    }

    &-container {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      margin-left: 5px;
      margin-bottom: 17px;

      > div {
        display: flex;
        justify-content: center;
        width: fit-content;
      }

      @include mq('large') {
        margin-bottom: 40px;
      }

      @include mq('large') {
        margin-bottom: 6px;
        margin-left: var(--emu-common-spacing-none);
      }

      .button {
        &:nth-child(2) {
          margin-right: 7px;
          margin-left: 7px;
        }
      }
    }
  }

  &__thumb {
    &.has-no-data {
      padding-top: 10px;
      .aaaem-carousel__actions,
      .aaaem-carousel__content {
        display: none;
      }
    }

    &-container {
      width: 100%; // value from live site
      margin-left: auto;
      margin-right: auto;

      @include mq('large') {
        width: 100%; // value from live site
        margin-bottom: 58px;
      }

      @include mq('large') {
        margin-bottom: var(--emu-common-spacing-none);
      }

      img {
        width: 72px;
        border: var(--emu-common-border-width-thin) solid
          var(--emu-common-colors-transparent);
        max-width: 72px; // natural width of the image
        box-sizing: content-box;
        cursor: pointer;

        &.active {
          opacity: 0.4;
        }
      }
    }
  }

  &__filter {
    &[data-component='button'] {
      padding: var(--emu-common-spacing-none);
      position: relative;
      -webkit-tap-highlight-color: var(--emu-common-colors-transparent);

      &,
      &:hover,
      &:focus,
      &:active {
        background-color: var(--emu-common-colors-transparent);
        border: none;
      }
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: var(--emu-common-sizing-medium); // value derived from live site
      height: var(--emu-common-sizing-medium); // value derived from live site
      background-color: var(--emu-semantic-colors-primary-red-100);
      border-radius: 50%;
    }

    &::after {
      content: '';
      position: absolute;
      top: 14px;
      left: 32px;
      width: 1.65em; // value to match live site
      height: 0.2em; // needed to match design
      background-color: var(--emu-semantic-colors-primary-red-100);
    }

    .cmp-button__text {
      color: var(--emu-semantic-colors-secondary-dark-gray-800);
      font-family: var(
        --emu-semantic-font-families-suisse-intl-condensed-light
      );
      font-size: 14px;
      line-height: 2em;
      font-weight: var(--emu-common-font-weight-bold);
      padding-top: var(--emu-common-spacing-large);
      position: relative;
      display: block;
      width: var(--emu-common-sizing-medium);

      &::after {
        content: '';
        display: block;
        width: 9px; // value from live site
        height: 18px; // value from live site
        border: solid var(--emu-common-colors-white);
        border-top-width: var(--emu-common-border-width-none);
        border-right-width: var(--emu-common-border-width-thick);
        border-bottom-width: var(--emu-common-border-width-thick);
        border-left-width: var(--emu-common-border-width-none);
        transform: rotate(45deg);
        position: absolute;
        top: 5px;
        left: 12px;
        display: none;
      }
    }

    &--active {
      &::before {
        background-color: var(--emu-semantic-colors-primary-red-300);
      }

      .cmp-button__text {
        &::after {
          display: block;
        }
      }
    }

    &-container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
      gap: 5px;

      @include mq('large') {
        padding-bottom: 55px;
      }

      @include mq('large') {
        padding-bottom: 25px;
      }

      > .button {
        width: var(--emu-common-sizing-medium);
        margin-left: 11px;
        margin-right: 11px;

        &:last-child {
          button {
            &::after {
              display: none;
            }
          }
        }
      }
    }
  }
}

.slider-carousel__comp-slider,
.slider-carousel__ba,
.slider-carousel__item-desc {
  @include aem-editor-view {
    display: block !important; // making sure that content stays authorable always
    position: static !important; // making sure that content stays authorable always
    height: auto !important; // making sure that content stays authorable always
    max-height: none !important; // making sure that content stays authorable always
  }
}

//hiding carousel items
.slider-carousel--hide-items {
  .slider-carousel__filter-container,
  .slider-carousel__thumb-container,
  .slider-carousel__control-container {
    display: none;
  }
}

/** Slider Carousel End **/
