body#about-skinvive {
  .teaser {
    width: 100%;
    overflow: hidden;

    .teaser-container {
      @include mq('large') {
        align-items: flex-start;
        height: 100%;
      }
    }

    .title-lockup {
      h1 {
        margin-top: var(--emu-common-spacing-none);
        margin-bottom: var(--emu-common-spacing-none);
      }

      @include mq('large') {
        margin-left: var(--emu-common-spacing-none);
      }
    }
    .cmp-teaser__description {
      i {
        font-style: normal;
        font-size: var(--emu-semantic-font-sizes-narrow-xl);
        font-family: var(--emu-semantic-font-families-body);
        position: relative;
        top: -5px;
        left: 4px;

        @include mq('large') {
          top: -8px;
          font-size: 39px;
        }
      }
    }
    .content-container {
      padding-bottom: var(--emu-common-spacing-none);
      margin-bottom: var(--emu-common-spacing-medium);

      @include mq('large') {
        margin-left: var(--emu-common-spacing-none);
        align-items: flex-end;
        justify-content: flex-end;
      }

      .content {
        @include mq('large') {
          width: 41%; // to match figma
          margin-right: var(--emu-common-spacing-none);
          position: relative;
          padding-bottom: 135px;
        }
      }

      .aaaem-teaser__subtitle {
        display: none;

        @include mq('large') {
          display: block;
          position: absolute;
          width: 93%; // to match figma
          text-align: left;
          font-size: var(--emu-semantic-font-sizes-wide-xs);
          line-height: var(--emu-semantic-line-heights-wide-small);
          bottom: 0;
          text-indent: -7px;
        }
      }

      .disclaimer {
        margin-bottom: 28px;
        visibility: visible;
      }

      .image--disclaimer {
        @include mq('large') {
          visibility: hidden;
        }
      }
    }
    .cmp-teaser {
      &__image {
        img {
          object-position: 10%;
        }
      }

      &__description {
        p {
          margin-bottom: var(--emu-common-spacing-none);

          b {
            font-family: var(--emu-semantic-font-families-body);
            font-weight: var(--emu-common-font-weight-light);

            @include mq('large') {
              font-size: var(--emu-semantic-font-sizes-wide-medium);
            }
          }

          @include mq('large') {
            font-size: var(--emu-semantic-font-sizes-wide-large);
          }
        }
        @include mq('medium') {
          width: 55%;
        }

        @include mq('large') {
          width: 100%;
        }
      }
    }
  }

  .about--results {
    padding-left: 22px;
    padding-right: 22px;

    @include mq('large') {
      display: none;
    }

    p {
      font-size: var(--emu-common-font-sizes-narrow-medium);
      line-height: var(--emu-semantic-line-heights-narrow-xxs);
      text-align: center;
    }
  }

  .about-clinically-proven-wrapper {
    margin-top: 30px;

    .aem-Grid {
      max-width: var(--emu-component-containers-container-max-width);

      @include mq('large') {
        align-items: center;
      }

      > div:nth-of-type(1) {
        @include mq('x-large') {
          width: 100%;
        }
      }
      > div:nth-of-type(2) {
        @include mq('x-large') {
          width: 100%;
        }
      }

      .plyr {
        border-radius: 23px;
      }

      .cmp-text {
        @include mq('large') {
          margin: auto;
          padding-top: 30px;

          p {
            text-align: center;
          }
        }
      }
    }

    p {
      text-align: center;
      margin: auto;
      width: 85%;
      padding-bottom: 40px;
      font: var(--emu-semantic-typography-narrow-body-intro-large);

      &:nth-of-type(2) {
        font: var(--emu-semantic-typography-narrow-body-footnotes);
        padding-bottom: 40px;
      }

      @include mq('large') {
        text-align: left;
        margin-left: auto;
        margin-right: auto;
        padding-bottom: var(--emu-common-spacing-none);
        width: 55%; // to match figma
        font: var(--emu-semantic-typography-wide-body-intro-large);

        &:nth-of-type(2) {
          font: var(--emu-semantic-typography-wide-body-footnotes);
          width: 45%; // to match figma
          padding-top: 39px;
          padding-bottom: var(--emu-common-spacing-none);
        }
      }
    }

    .embed {
      margin-bottom: 53px;

      @include mq('large') {
        margin-bottom: 41px;
        align-self: flex-start;
      }

      .aaaem-embed {
        margin-left: auto;
        margin-right: auto;
        width: 88%; // to match figma

        @include mq('large') {
          width: 820px; // to match figma
        }
      }

      .cmp-embed {
        div {
          border-radius: var(--emu-semantic-border-radius-medium);
        }
      }
    }

    @include mq('large') {
      margin-top: 93px;
      margin-bottom: 50px;
    }
  }

  .about-checker-wrapper > .cmp-container > .aem-Grid > .text {
    @include mq('large') {
      width: calc(50% - 11px);
    }
  }

  .about--image--text--container {
    &__even,
    &__odd {
      .aem-Grid > .teaser {
        .image--disclaimer {
          visibility: visible;
        }
      }
      p {
        b {
          font-family: var(--emu-semantic-font-families-body);
        }
      }
    }

    &__even {
      > .aem-Grid {
        > .teaser {
          margin-left: auto;
        }

        > .text {
          p {
            .emphasis {
              color: inherit;
              display: inline-block;
            }
          }
        }
      }
    }

    &__odd {
      > .aem-Grid > .text {
        margin-left: auto;
      }
    }
  }

  .about-checker-wrapper {
    padding-bottom: 40px;

    @include mq('large') {
      padding-bottom: 22px;
    }

    .aem-Grid {
      width: 86%;
      margin: auto;
      display: flex;
      flex-direction: column-reverse;
      max-width: var(--emu-component-containers-container-max-width);

      @include mq('large') {
        flex-direction: row;
      }
    }

    .cmp-text {
      background-color: var(--emu-semantic-colors-primary-bone);
      border-radius: 23px;
      overflow: hidden;
      padding-top: 36px;
      padding-right: 20px;
      padding-bottom: 36px;
      padding-left: 25px;

      @include mq('large') {
        padding-top: 58px;
        padding-right: 92px;
        padding-bottom: 57px;
        padding-left: 91px;
        height: 100%;
      }

      h3 {
        font-family: var(--emu-semantic-font-families-body);
        font-weight: var(--emu-common-font-weight-regular);
        font-size: 25px;
        line-height: normal;
        margin-top: var(--emu-common-spacing-none);
        margin-bottom: 21px;
        color: var(--emu-semantic-colors-primary-teal-400);

        @include mq('large') {
          font-weight: 500;
          margin-bottom: 10px;
        }

        &:not(:first-child) {
          margin-top: 44px;

          @include mq('large') {
            margin-top: 40px;
          }
        }
      }

      p {
        font-size: 18px;
        line-height: 25px;
        margin-top: var(--emu-common-spacing-none);
        margin-bottom: var(--emu-common-spacing-none);

        @include mq('large') {
          font-size: 20px;
          line-height: 30px;
          letter-spacing: -0.3px;
          &.page-reference {
            font-size: var(--emu-semantic-font-sizes-wide-xs);
            line-height: var(--emu-semantic-line-heights-wide-small);
            width: 100%;
          }
        }
      }
    }

    .teaser {
      @include mq('large') {
        width: calc(50% - 11px);
      }

      .has-media {
        @include mq('large') {
          height: 100%;
        }
      }

      .teaser-container {
        .cmp-teaser__image {
          &,
          .cmp-image,
          .cmp-image__link {
            margin-bottom: 24px;
            @include mq('large') {
              height: 100%;
              margin-bottom: 0;
              width: 100%;
            }
          }

          img {
            border-radius: 23px;
            height: auto;
            max-width: 500px; // to make it look cleaner in larger resolutions
            margin-left: auto;
            margin-right: auto;

            @include mq('large') {
              object-fit: cover;
              height: 100%;
              max-width: unset;
              position: static;
              object-position: center;
            }
          }
        }
        .content-container {
          margin-bottom: 0px;
          position: relative;
          top: -40px;
          align-items: center;

          @include mq('large') {
            position: absolute;
            top: auto;
            bottom: 15px;
          }

          .disclaimer {
            margin-bottom: var(--emu-common-spacing-none);
            padding-right: var(--emu-common-spacing-none);

            p {
              color: var(--emu-common-colors-white);
            }
          }
        }
      }
    }

    &:nth-of-type(odd) {
      .cmp-container .aem-Grid {
        @include mq('large') {
          flex-direction: row-reverse;
        }
      }
    }

    &:nth-of-type(even) {
      .cmp-container .aem-Grid {
        @include mq('large') {
          flex-direction: row;
        }
      }
    }
    .about--image--text--container__even,
    .about--image--text--container__odd {
      &.graph-type {
        .teaser-container {
          a {
            @include mq('large') {
              border-radius: 23px;
              display: flex;
              overflow: hidden;
            }
            img {
              border-radius: 0;
              @include mq('large') {
                height: auto;
                margin: auto;
                object-fit: contain;
              }
            }
          }
        }
      }
      &.cal-type {
        .aaaem-teaser {
          display: flex;
          height: 100%;
          .teaser-container {
            height: auto;
            margin: auto;
            display: flex;
            flex-direction: column;
            width: 100%;
            @include mq('large') {
              
            }
            .aaaem-teaser__image {
              margin: auto;
            }
            .emu-image__link {
              display: block;
              position: relative;
            }
            svg {
              display: block;
              margin: auto;
              padding: 30px;
              width: 50%;
            }
            .content-container {
              display: flex;
              position: relative;
              .content {
                padding-bottom: 0;
              }
            }
          }
        }
      }
    }
  }

  .about-expect-from-treatment-wrapper {
    margin-bottom: 102px;

    @include mq('large') {
      margin-bottom: 152px;
    }

    .aem-Grid {
      width: 86%;
      margin: auto;
      max-width: var(--emu-component-containers-container-max-width);
      background-color: var(--emu-semantic-colors-primary-bone);
      border-radius: 23px;
      .embed .cmp-embed {
        div {
          border-radius: var(--emu-semantic-border-radius-medium);
        }
      }
    }

    .text {
      width: 100%;

      h2 {
        width: 90%;
        text-align: center;
        padding-top: 42px;
        margin: auto;

        @include mq('large') {
          font-size: var(--emu-semantic-font-sizes-wide-xl);
          line-height: var(--emu-semantic-line-heights-wide-xxl);
          font-weight: var(--emu-common-font-weight-light);
          width: 100%;
          padding-bottom: 37px;
        }
      }
      ul {
        padding-left: 10px;
        padding-right: 40px;
        padding-bottom: 26px;
        position: relative;
        margin-bottom: var(--emu-common-spacing-none);

        @include mq('large') {
          margin-top: var(--emu-common-spacing-none);
          padding-right: var(--emu-common-spacing-none);
          padding-left: var(--emu-common-spacing-none);
        }

        li {
          font: var(--emu-semantic-typography-narrow-body-regular);
          padding-bottom: var(--emu-common-spacing-medium);
          list-style-type: none;
          display: -webkit-box;

          &::before {
            content: '';
            position: relative;
            background-color: var(--emu-semantic-colors-primary-teal-300);
            width: 13px;
            height: 13px;
            border-radius: 50%;
            border-top-right-radius: var(--emu-common-border-radius-none);
            margin-top: var(--emu-common-spacing-small);
            margin-right: 11px;
            transform: rotate(-45deg);
            display: flex;
          }

          @include mq('large') {
            font: var(--emu-semantic-typography-wide-body-regular);
            width: 73%;
            margin-left: auto;
            margin-right: auto;
          }
        }
      }
    }
  }
}
