.modal {
  .modal-content {
    border-radius: var(--emu-semantic-border-radius-xs);
    padding-bottom: 35px;
    padding-top: 35px;

    @include mq('large') {
      padding-top: 42px;
      padding-left: var(--emu-common-spacing-large);
      padding-right: var(--emu-common-spacing-large);
    }

    .close {
      padding-top: var(--emu-common-spacing-small);
      padding-right: 10px;
      padding-bottom: var(--emu-common-spacing-small);
      padding-left: 10px;
      @include mq('large') {
        padding-top: 14px;
        padding-right: 18px;
        padding-bottom: 14px;
        padding-left: 18px;
      }
    }

    .aaaem-container {
      display: flex;
      flex-flow: wrap;
      margin: auto;

      .cmp-button {
        min-width: 80px;
      }
    }

    .aaaem-text {
      width: 100%;
      padding-bottom: 15px;

      a {
        color: var(--emu-semantic-colors-primary-black);
        text-decoration: none;
      }

      h3 {
        font: var(--emu-semantic-typography-narrow-headings-headline-h4);
        font-size: var(--emu-semantic-font-sizes-narrow-small);
        margin-top: 5px;
        @include mq('large') {
          font-size: var(--emu-semantic-font-sizes-narrow-large);
          margin-top: var(--emu-common-spacing-none);
          margin-bottom: 3px;
        }
      }

      p {
        font: var(--emu-semantic-typography-narrow-body-regular);
        margin: var(--emu-common-spacing-none);
        line-height: var(--emu-semantic-line-heights-wide-small);
        @include mq('large') {
          font: var(--emu-semantic-typography-wide-body-regular);
          line-height: 1.2;
        }
      }
    }

    .modal-cta-container {
      justify-content: flex-end;
      flex-direction: row-reverse;

      @include mq('large') {
        margin-bottom: 5px;
      }

      .button {
        padding-right: 15px;
      }

      .aaaem-button {
        font: var(--emu-semantic-typography-narrow-body-regular);
        padding-bottom: var(--emu-common-spacing-xs);
        padding-top: var(--emu-common-spacing-xs);
      }
    }
  }
  // ============ brand navigation ===========
  [data-id='brand-nav'] {
    .modal-wrapper {
      overflow: auto;
      @include horizontal-slide-animation(-100%, 0);
      @include mq('large') {
        @include horizontal-slide-animation(0, 0);
        @include color-change-animation(
          var(--emu-component-layers-modal-scrim-color-background),
          transparent
        );
      }

      @include mq('large') {
        background-color: var(--emu-common-colors-transparent);
        animation: none;
      }

      &.is-open {
        @include mq('large') {
          height: auto;
        }
      }
    }

    .modal-content,
    .aaaem-container {
      background-color: var(--emu-semantic-colors-primary-teal-200);
      @include mq('large') {
        background-color: var(--emu-semantic-colors-primary-white-solid);
      }
    }
    .modal-content {
      border-radius: var(--emu-common-border-radius-none);
      margin: var(--emu-common-spacing-none);
      max-width: none;
      padding-left: 25px;
      padding-right: 25px;
      padding-bottom: 66px;
      width: 100%;
      @include mq('large') {
        @include slide-animation(0, 0, -100%, 0, bannerSlide);
        padding-bottom: 24px;
        padding-left: var(--emu-common-spacing-none);
        padding-right: var(--emu-common-spacing-none);
        padding-top: 24px;
        transform: translateY(-100%);
        animation-fill-mode: forwards;
      }
      .main-close {
        border: var(--emu-common-border-width-thin) solid
          var(--emu-semantic-colors-primary-teal-400);
        border-radius: 50%;
        display: flex;
        height: 38px;
        margin-top: 25px;
        margin-right: 25px;
        padding: var(--emu-common-spacing-none);
        width: 38px;
        @include mq('large') {
          border: none;
          margin-right: 25px;
          margin-top: 14;
        }
        span {
          font-size: 0;
        }
        &::before,
        &::after {
          background-color: var(--emu-semantic-colors-primary-teal-400);
          content: '';
          display: block;
          height: 20px;
          margin: auto;
          transform: rotate(45deg);
          width: var(--emu-common-sizing-xxs);
          @include mq('large') {
            background-color: var(--emu-semantic-colors-primary-black);
            width: 2px;
          }
        }
        &::after {
          left: 17px;
          position: absolute;
          top: var(--emu-common-spacing-small);
          transform: rotate(-45deg);
          @include mq('large') {
            top: 9px;
            left: 18px;
          }
        }
      }

      > .container {
        margin: var(--emu-common-spacing-none);
      }
    }
    #brand-nav {
      .button {
        width: auto;
        @include mq('large') {
          display: none;
        }
      }
      button {
        &[data-modal='brand-nav'] {
          background-color: var(--emu-common-colors-transparent);
          border: none;
          color: var(--emu-semantic-colors-primary-black);
          font: var(--emu-semantic-typography-narrow-body-regular);
          font-size: var(--emu-semantic-font-sizes-wide-xs);
          line-height: var(--emu-semantic-line-heights-narrow-xs);
          padding-left: var(--emu-common-spacing-none);
          pointer-events: none;
        }
      }
      h2 {
        font-family: var(--emu-common-font-families-sans);
        font-size: var(--emu-semantic-font-sizes-narrow-xs);
        line-height: 26px;
        margin-bottom: var(--emu-common-spacing-none);
        margin-top: var(--emu-common-spacing-none);
        @include mq('large') {
          font-size: var(--emu-common-font-sizes-wide-medium);
        }
      }
      p,
      span {
        font-size: var(--emu-common-font-sizes-narrow-medium);
        line-height: 12px;
        @include mq('large') {
          font-size: 10px;
          line-height: 10px;
        }
      }
      .text {
        display: flex;
        padding-bottom: var(--emu-common-spacing-none);
        @include mq('large') {
          text-align: center;
        }
        &:not(.aaaem-text) {
          border-bottom: solid 1px var(--emu-semantic-colors-primary-teal-400);
          padding-bottom: 10px;
          padding-top: 10px;
          @include mq('large') {
            border-bottom: none;
          }
        }
        .aaaem-text {
          display: block;
          @include mq('large') {
            margin: auto;
            padding: var(--emu-common-spacing-none);
          }
        }
      }
      > div {
        @include mq('large') {
          display: flex;
          .text:not(:first-child):not(:nth-child(2)) {
            border-left: solid var(--emu-common-border-width-thin)
              var(--emu-semantic-colors-primary-black);
            padding-left: var(--emu-common-spacing-xs);
            padding-right: var(--emu-common-spacing-xs);
          }
        }
      }
    }
  }
}
