// Animation Variables
$standardAnimationDuration: 1s;
$standardAnimationDelay: 0.2s;
$noAnimationDelay: 0s;
$standardEase: ease;

// ****************************************************************************
// Animation Mixins

// Example Usage: @include fade-animation(0, 100, cardAnimation, 'out')
@mixin fade-animation(
  $startOpacity: 0,
  $endOpacity: 100%,
  $name: 'fade',
  $direction: ease
) {
  animation-name: $name;
  animation-duration: $standardAnimationDuration;
  animation-delay: $standardAnimationDelay;

  // Direction is to set whether you are fading something onto ("in") the page or off ("out") of the page
  @if $direction == 'in' {
    animation-timing-function: ease-out;
  } @else if $direction == 'out' {
    animation-timing-function: ease-in;
  } @else {
    animation-timing-function: $standardEase;
  }

  @keyframes #{$name} {
    from {
      opacity: $startOpacity;
    }
    to {
      opacity: $endOpacity;
    }
  }
}

// ****************************************************************************

// Example Usage: @include slide-animation(-25px, 0px, 0, 0, cardAnimation)
@mixin slide-animation(
  $startX: 0,
  $endX: 0,
  $startY: 0,
  $endY: 0,
  $name: slide
) {
  animation-name: $name;
  animation-duration: $standardAnimationDuration;
  animation-delay: $standardAnimationDelay;
  animation-timing-function: $standardEase;

  @keyframes #{$name} {
    from {
      transform: translateX($startX) translateY($startY);
    }
    to {
      transform: translateX($endX) translateY($endY);
    }
  }
}

// ****************************************************************************

// Example Usage: @include color-change-animation(transparent, #fff, colorChange)
@mixin color-change-animation(
  $startBackgroundColor: transparent,
  $endBackgroundColor: black,
  $name: colorChange
) {
  animation-name: $name;
  animation-duration: $standardAnimationDuration;
  animation-delay: $standardAnimationDelay;
  animation-timing-function: $standardEase;
  animation-fill-mode: forwards;

  @keyframes #{$name} {
    from {
      background-color: $startBackgroundColor;
    }
    to {
      background-color: $startBackgroundColor;
    }
  }
}

// ****************************************************************************

// Example Usage: @include horizontal-slide-animation(-25px, 0px, cardAnimation)
@mixin horizontal-slide-animation($startX: 0, $endX: 0, $name: slide) {
  animation-name: $name;
  animation-duration: $standardAnimationDuration;
  animation-delay: $noAnimationDelay;
  animation-timing-function: $standardEase;

  @keyframes #{$name} {
    from {
      transform: translateX($startX);
    }
    to {
      transform: translateX($endX);
    }
  }
}

// ****************************************************************************

// Example Usage: @include fade-and-slide-animation(0, 100, -15px, 0px, 0, 0, cardAnimation, 'in')
@mixin fade-and-slide-animation(
  $startOpacity: 0,
  $endOpacity: 100%,
  $startX: 0,
  $endX: 0,
  $startY: 0,
  $endY: 0,
  $name: fadeAndSlide,
  $direction: 'ease'
) {
  animation-name: $name;
  animation-duration: $standardAnimationDuration;
  animation-delay: $standardAnimationDelay;

  // Direction is to set whether you are fading something onto ("in") the page or off ("out") of the page
  @if $direction == 'in' {
    animation-timing-function: ease-out;
  } @else if $direction == 'out' {
    animation-timing-function: ease-in;
  } @else {
    animation-timing-function: $standardEase;
  }

  @keyframes #{$name} {
    from {
      opacity: $startOpacity;
      transform: translateX($startX) translateY($startY);
    }
    to {
      opacity: $endOpacity;
      transform: translateX($endX) translateY($endY);
    }
  }
}

// ****************************************************************************

// Example Usage: @include rotate-animation(20deg, 90deg, rotateImage)
@mixin rotate-animation($start, $end, $name: rotate) {
  animation-name: $name;
  animation-duration: 0.25s;
  animation-timing-function: $standardEase;

  @keyframes #{$name} {
    from {
      transform: rotate($start);
    }
    to {
      transform: rotate($end);
    }
  }
}

// ****************************************************************************

// Example Usage: @include stagger-animation((.25s, .55s, .80s), '&', 'animation')
@mixin stagger-animation($delays, $selector, $delay-property: 'transition') {
  @for $i from 1 through length($delays) {
    $delay: nth($delays, $i);
    #{$selector}:nth-child(#{$i}) {
      #{$delay-property}-delay: $delay;
    }
  }
}
