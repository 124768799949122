body#science {
  .teaser {
    overflow: hidden;

    .teaser-container {
      justify-content: flex-end;

      @include mq('large') {
        justify-content: center;
      }

      a {
        display: flex;
      }

      svg,
      img {
        margin: auto;
        object-position: 15%;
        width: 100%;
      }

      .content-container {
        padding: var(--emu-common-spacing-none);
        margin-left: var(--emu-common-spacing-none);

        @include mq('large') {
          max-width: var(--emu-component-containers-container-max-width);
          display: flex;
          align-content: flex-end;
        }

        @include mq('x-large') {
          margin-right: 63px;
        }

        .content-middle-right {
          @include mq('large') {
            padding-bottom: 60px;
            width: 500px;

            .cmp-teaser__description {
              margin-top: 20px;

              p {
                font-size: var(--emu-semantic-font-sizes-wide-large);
              }
            }
          }
        }

        .disclaimer {
          font: var(--emu-semantic-typography-narrow-body-footnotes);
          align-self: flex-start;
          padding-left: 22px;

          @include mq('large') {
            align-self: flex-end;
            font: var(--emu-semantic-typography-wide-body-footnotes);
            padding-bottom: var(--emu-common-spacing-medium);
          }

          p {
            margin-bottom: var(--emu-common-spacing-none);
          }
        }
      }
    }
    .cmp-teaser {
      &__title {
        margin-top: var(--emu-common-spacing-none);
      }
      &__description {
        p {
          sup {
            font-size: 50%;
            line-height: 0;
            top: -3px;
          }
        }
      }
    }
  }

  .body-intro {
    display: flex;
    justify-content: center;

    @include mq('large') {
      margin-top: var(--emu-common-spacing-large);
      margin-bottom: 40px;
    }

    .text {
      max-width: 936px;

      @include mq('large') {
        width: 64%;
      }

      p {
        font: var(--emu-semantic-typography-narrow-body-intro-large);
        margin-top: 47px;
        margin-left: auto;
        margin-right: auto;

        @include mq('large') {
          font: var(--emu-semantic-typography-wide-body-intro-large);
        }

        sup {
          font-size: 50%;
          line-height: initial;
          position: relative;
          line-height: 0;
        }

        &:nth-of-type(1) {
          width: 93%;

          @include mq('large') {
            width: 100%;
          }
        }

        &:nth-of-type(2) {
          width: 87%; // to match figma
          margin-top: 36px;
          font-size: var(--emu-semantic-font-sizes-narrow-xs);
          line-height: var(--emu-semantic-line-heights-narrow-small);

          @include mq('large') {
            width: 100%;
            font-size: var(--emu-semantic-font-sizes-narrow-large);
            line-height: var(--emu-semantic-line-heights-narrow-large);
          }

          sup {
            font-size: 50%;
            line-height: initial;
            position: relative;
            line-height: 0;
          }
        }
      }
    }
  }

  .body__content-container {
    margin-top: var(--emu-common-spacing-xl);
    display: flex;
    justify-content: center;

    @include mq('large') {
      margin-top: 90px;
    }

    .teaser {
      @include mq('large') {
        width: 44%;
      }
      .has-media {
        height: 100%;
      }
    }

    .science--body--teaser {
      .teaser-container {
        img {
          height: max-content;

          @include mq('large') {
            object-fit: cover;
            height: 100%;
          }
        }

        .content-container {
          @include mq('large') {
            margin-right: var(--emu-common-spacing-none);
          }
        }

        .disclaimer {
          visibility: visible;
          position: absolute;
          bottom: 13px;
          color: var(--emu-common-colors-white);
          margin-bottom: var(--emu-common-spacing-none);
          padding-left: var(--emu-common-spacing-none);
          align-self: center;

          @include mq('large') {
            bottom: -5px; //to match figma
          }
        }
      }
    }

    .cmp-container {
      display: flex;
      flex-direction: column;
      max-width: var(--emu-component-containers-container-max-width);

      @include mq('large') {
        flex-direction: row;
        flex-wrap: nowrap;
        width: 100%;
      }

      @include mq('xx-large') {
        justify-content: center;
      }

      .body__content-container__text-container {
        display: flex;
        justify-content: center;

        @include mq('large') {
          padding: var(--emu-common-spacing-none);
        }

        .aaaem-container {
          flex-direction: column;
          gap: 21px;
          margin-top: 53px;
          width: 89%;

          @include mq('large') {
            margin-top: var(--emu-common-spacing-none);
            width: auto;
            padding-left: 26px;
            gap: 26px;
          }

          .cmp-text {
            background-color: var(--emu-semantic-colors-primary-bone);
            padding-top: 44px;
            padding-right: 25px;
            padding-bottom: 44px;
            padding-left: 25px;

            @include mq('large') {
              padding-top: 52px;
              padding-right: 92px;
              padding-bottom: 58px;
              padding-left: 82px;
              max-width: 787px;
            }

            h3 {
              margin-top: var(--emu-common-spacing-none);
              color: var(--emu-semantic-colors-primary-teal-400);
              margin-bottom: 12px;
              font: var(
                --emu-semantic-typography-narrow-headings-headline-h4-teal
              );

              @include mq('large') {
                font: var(
                  --emu-semantic-typography-wide-headings-headline-h4-teal
                );
                margin-bottom: 11px;
              }
            }

            p {
              font: var(--emu-semantic-typography-narrow-body-regular);
              margin-top: var(--emu-common-spacing-none);
              margin-bottom: var(--emu-common-spacing-none);
              @include mq('large') {
                font: var(--emu-semantic-typography-wide-body-regular);
                margin-bottom: var(--emu-common-spacing-none);
              }
            }
          }
        }
      }
    }
  }

  .body-cta-container {
    margin-top: 61px;
    margin-bottom: 101px;
    display: flex;
    justify-content: center;
    padding-bottom: 10px;

    @include mq('large') {
      margin-top: 132px;
      margin-bottom: 136px;
    }
    .cmp-container {
      width: 85%;
      @include mq('large') {
        width: 55%;
      }
      p {
        font: var(--emu-semantic-typography-narrow-headings-headline-h2);
        margin-top: var(--emu-common-spacing-none);
        padding-left: 10px;
        padding-right: 10px;
        @include mq('large') {
          margin-bottom: 47px;
          font: var(--emu-semantic-typography-wide-headings-headline-h1);
        }
      }
    }
    .button {
      justify-content: center;

      a {
        width: auto;
        min-width: 130px;

        > span {
          display: none;
        }

        svg {
          width: 100%;
        }

        @include mq('large') {
          padding-top: 11px;
          padding-bottom: 11px;
          min-width: 157px;
        }
      }

      svg {
        transition: all 0.3s ease-out;
        margin: var(--emu-common-spacing-none);
      }
      .aaaem-button {
        &:hover,
        &:focus {
          svg {
            transform: translateX(5%);
            g {
              stroke: var(--emu-semantic-colors-primary-teal-400);
            }
          }
        }
        &:active {
          svg g {
            stroke: var(--emu-semantic-colors-primary-teal-100);
          }
        }
      }
    }
  }

  .science-checker-wrapper > .cmp-container > .aem-Grid > .text {
    @include mq('large') {
      width: calc(50% - 11px);
    }
  }

  .science--image--text--container {
    &__even,
    &__odd {
      .aem-Grid > .teaser {
        .image--disclaimer {
          visibility: visible;
        }
      }
    }

    &__even {
      > .aem-Grid {
        > .teaser {
          margin-left: auto;
        }

        > .text {
          p {
            .emphasis {
              color: inherit;
              display: inline-block;
            }
          }
        }
      }
    }

    &__odd {
      > .aem-Grid > .text {
        margin-left: auto;
      }
    }
  }

  .science-checker-wrapper {
    padding-bottom: 40px;

    @include mq('large') {
      padding-bottom: 22px;
    }

    .aem-Grid {
      width: 86%;
      margin: auto;
      display: flex;
      flex-direction: column-reverse;
      max-width: var(--emu-component-containers-container-max-width);

      @include mq('large') {
        flex-direction: row;
      }
    }

    .cmp-text {
      background-color: var(--emu-semantic-colors-primary-bone);
      border-radius: 23px;
      overflow: hidden;
      padding-top: 36px;
      padding-right: 20px;
      padding-bottom: 36px;
      padding-left: 25px;

      @include mq('large') {
        padding-top: 58px;
        padding-right: 92px;
        padding-bottom: 57px;
        padding-left: 91px;
        height: 100%;
      }

      h3 {
        font-family: var(--emu-semantic-font-families-body);
        font-weight: var(--emu-common-font-weight-regular);
        font-size: 25px;
        line-height: normal;
        margin-top: var(--emu-common-spacing-none);
        margin-bottom: 21px;
        color: var(--emu-semantic-colors-primary-teal-400);

        @include mq('large') {
          font-weight: 500;
          margin-bottom: 10px;
        }

        &:not(:first-child) {
          margin-top: 44px;

          @include mq('large') {
            margin-top: 40px;
          }
        }
      }

      p {
        font-size: 18px;
        line-height: 25px;
        margin-top: var(--emu-common-spacing-none);
        margin-bottom: var(--emu-common-spacing-none);

        @include mq('large') {
          font-size: 20px;
          line-height: 30px;
          letter-spacing: -0.3px;
        }
      }
    }

    .teaser {
      @include mq('large') {
        width: calc(50% - 11px);
      }

      .has-media {
        @include mq('large') {
          height: 100%;
        }
      }

      .teaser-container {
        .cmp-teaser__image {
          &,
          .cmp-image,
          .cmp-image__link {
            @include mq('large') {
              height: 100%;
              width: 100%;
            }
          }

          img {
            border-radius: 23px;
            height: auto;
            max-width: 500px; // to make it look cleaner in larger resolutions
            margin-left: auto;
            margin-right: auto;

            @include mq('large') {
              object-fit: cover;
              max-width: unset;
              position: static;
              object-position: center;
            }
          }
        }
        .content-container {
          margin-bottom: 0px;
          position: relative;
          top: -40px;
          align-items: center;

          @include mq('large') {
            position: absolute;
            top: auto;
            bottom: 15px;
          }

          .disclaimer {
            margin-bottom: var(--emu-common-spacing-none);
            padding-right: var(--emu-common-spacing-none);

            p {
              color: var(--emu-common-colors-white);
            }
          }
        }
      }
    }

    &:nth-of-type(odd) {
      .cmp-container .aem-Grid {
        @include mq('large') {
          flex-direction: row-reverse;
        }
      }
    }

    &:nth-of-type(even) {
      .cmp-container .aem-Grid {
        @include mq('large') {
          flex-direction: row;
        }
      }
    }
  }
  div {
    >.science-checker-wrapper {
      &:nth-of-type(1) {
        .emu-image {
          margin-bottom: 32px;
          margin-top: 32px;
          @include mq('large') {
            margin-bottom: 0;
            margin-top: 0;
          }
        }
      }
      &:nth-of-type(2) {
        .emu-image {
          margin-bottom: 32px;
          @include mq('large') {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
