footer {
  position: relative;
  background-color: var(--emu-semantic-colors-primary-teal-300);
  padding-top: var(--emu-common-spacing-medium);

  @include mq('large') {
    padding-top: 85px;
  }

  .upper_section > div:nth-of-type(2) > div:first-child,
  .lower_section {
    width: 95%;
    max-width: var(--emu-component-containers-container-max-width);
    margin: auto;

    @include mq('large') {
      width: 100%;
      padding-top: var(--emu-common-spacing-none);
      padding-right: 70px;
      padding-bottom: var(--emu-common-spacing-none);
      padding-left: 70px;
      display: flex;
    }
  }

  .upper_section
    > div:nth-of-type(2)
    > div:first-child
    > .container:nth-of-type(3) {
    @include mq('large') {
      flex: 20%;
    }
  }

  .upper_section {
    p {
      color: var(--emu-common-colors-white);
      margin: var(--emu-common-spacing-none);
      font: var(--emu-semantic-typography-narrow-body-footnotes);
      font-family: var(--emu-semantic-font-families-medium);
      font-weight: var(--emu-common-font-weight-bold);
      @include mq('large') {
        font: var(--emu-semantic-typography-narrow-body-small);
      }
    }
  }

  .lower_section {
    p {
      color: var(--emu-common-colors-white);
      font: var(--emu-semantic-typography-narrow-body-footnotes);
      font-family: var(--emu-semantic-font-families-regular);
      @include mq('large') {
        font: var(--emu-semantic-typography-narrow-body-small);
      }
    }
  }

  .upper_section > div:nth-of-type(2) > div:first-child > .image:first-child {
    @include mq('large') {
      flex: 35%;
    }

    @include mq('x-large') {
      flex: 30%;
    }
  }

  .upper_section {
    padding-bottom: var(--emu-common-spacing-medium);

    @include mq('large') {
      padding-bottom: 68px;
    }

    .aaaem-container {
      background-color: var(--emu-common-colors-transparent);
    }

    .aem-Grid div {
      p {
        padding-bottom: 14px;

        @include mq('large') {
          padding-bottom: var(--emu-common-spacing-medium);
        }
      }

      a {
        text-decoration: none;
        color: inherit;
      }
    }
  }

  .footer-contents {
    > div {
      display: flex;

      div:nth-child(1) {
        flex-basis: 285px;

        @include mq('large') {
          flex-basis: unset;
        }
      }
    }
  }

  .footer-contents > .aem-Grid:first-child > .text:first-child {
    @include mq('large') {
      flex: 30%;
    }
  }

  .footer-right-container {
    .aem-Grid:first-child {
      @include mq('large') {
        display: flex;
      }
    }

    .text:first-child {
      @include mq('large') {
        flex: 70%;
      }
    }
  }

  .footer-right-bottom {
    p:nth-child(4) {
      a {
        display: flex;

        img {
          margin-left: 5px;
        }
      }
    }
  }

  .footer-social-media {
    margin-top: 15px;

    @include mq('large') {
      margin-top: var(--emu-common-spacing-none);
    }

    .aem-Grid {
      .text {
        flex-basis: 285px;

        @include mq('large') {
          flex-basis: unset;
        }
      }
    }
  }

  .footer-social-media > .aem-Grid:first-child {
    display: flex;

    @include mq('large') {
      flex-direction: column;
    }

    .text {
      width: 100%;
    }

    .image {
      padding-top: var(--emu-common-spacing-none);
    }
  }

  .footer-social-media__image .aem-Grid:first-child {
    display: flex;
    flex-direction: row;
  }

  .footer-social-media__image > .aem-Grid:first-child > .image:first-child {
    width: 22px;

    @include mq('large') {
      width: 40px;
    }
  }

  .footer-social-media__image {
    img {
      width: 21px;

      @include mq('large') {
        width: 40px;
      }
    }

    .footer-instagram-icon {
      margin-left: 10px;
    }
  }

  .image {
    margin-bottom: 18px;

    @include mq('large') {
      margin-top: var(--emu-common-spacing-none);
    }

    svg {
      width: 136px;
      height: var(--emu-common-sizing-medium);

      @include mq('large') {
        width: 165px;
        height: 39px;
        margin-top: -7px;
      }
    }
  }

  .image {
    &:not(:first-child) {
      max-width: fit-content;
      margin: var(--emu-common-spacing-none);
      padding-right: 14px;
      svg {
        width: 21px;
        height: 21px;

        @include mq('large') {
          width: 40px;
          height: 40px;
        }
      }

      @include mq('large') {
        padding-top: 10px;
        padding-right: 28px;
      }
    }
  }

  .footer {
    &__copyright {
      border-top: solid var(--emu-common-border-width-medium)
        var(--emu-semantic-colors-primary-white-solid);
      padding-top: 23px;
      padding-bottom: 40px;

      @include mq('large') {
        padding-top: 35px;
        padding-bottom: 65px;
      }

      p {
        margin: var(--emu-common-spacing-none);
      }

      p:first-child {
        padding-bottom: 19px;
        @include mq('large') {
          padding-top: 6px;
          padding-bottom: 6px;
        }
      }

      img {
        width: 88px;

        @include mq('large') {
          width: 176px;
        }
      }

      @include mq('large') {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 47px;

        p:nth-of-type(2) {
          margin-left: 60px;
        }
      }
    }
  }
}
