.aaaem-back-to-top-button {
  background-color: var(--emu-common-colors-transparent);
  border: none;
  width: auto;

  &:focus {
    outline: none;
  }

  svg {
    margin: var(--emu-common-spacing-none);
    fill: var(--emu-semantic-colors-primary-teal-300);
  }
  span {
    display: none;
  }
  &.js-absolute {
    position: absolute;
    bottom: 22px;
    @include mq('x-large') {
      bottom: 49px;
    }
  }
}
