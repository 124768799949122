// set visibility by device/screen size
.desktop-view {
  display: none;

  @include mq('large') {
    display: block;
  }
}

// visible on tablet size & larger
.tablet-desktop-view {
  display: none;

  @include mq('medium') {
    display: block;
  }
}

// visible on tablet size & smaller
.tablet-mobile-view {
  display: block;

  @include mq('large') {
    display: none;
  }
}

.mobile-view {
  display: block;

  @include mq('medium') {
    display: none;
  }
}

.d-none {
  display: none !important;
}

// padding adjustments
.no-pad {
  padding: 0;
}

.no-x-pad {
  padding-left: 0;
  padding-right: 0;
}

.no-y-pad {
  padding-top: 0;
  padding-bottom: 0;
}

//max width 1441px.
.container .aaaem-container.container--large {
  @include mq('large') {
    max-width: var(--emu-semantic-sizing-breakpoints-xx-large);
    margin-left: auto;
    margin-right: auto;
  }
}
