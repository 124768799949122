body#home {
  .home-teaser-container {
    position: relative;
  }

  .teaser {
    .content-container {
      padding-top: var(--emu-common-spacing-none);
      padding-bottom: var(--emu-common-spacing-none);
      justify-content: flex-end;
      width: 100%;
      padding-left: 22px;
      padding-right: 22px;

      @include mq('large') {
        padding-top: 90px;
        height: 100%;
        justify-content: space-between;
        padding-left: 90px;
        margin: auto;
        max-width: var(--emu-component-containers-container-max-width);
      }
    }
    .content {
      order: 1;
    }

    .title-lockup {
      margin: var(--emu-common-spacing-none);
      min-height: 493px; // needed to match the design
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      margin-top: 15px;
      max-width: none;

      @include mq('large') {
        max-width: var(--emu-component-cards-teaser-title-max-width);
        min-height: fit-content;
        flex-shrink: 0;
      }

      p {
        @include mq('large') {
          position: relative;
          top: 145px;
        }

        @include mq('x-large') {
          margin: var(--emu-common-spacing-none);
          top: 225px;
          line-height: 1;
        }
      }

      h1 {
        @include mq('large') {
          top: 21%;
          left: auto;
          right: auto;
          position: absolute;
          line-height: 1;
        }

        @include mq('x-large') {
          top: 18%;
        }
      }
    }

    .cmp-teaser {
      @include mq('large') {
        height: 815px; // needed to match the design
      }

      &__image {
        img {
          height: 493px;
          object-fit: cover;
          object-position: 45%;

          @include mq('large') {
            height: 100%;
          }
        }
      }

      &__title {
        @include mq('large', 'max-width') {
          margin-bottom: 35px;
        }
      }

      &__title {
        position: relative;
        text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
        font-family: var(--emu-semantic-font-families-juana-light);
        font-size: var(--emu-semantic-font-sizes-wide-large);
        color: var(--emu-semantic-colors-primary-white-solid);

        @include mq('large') {
          text-align: left;
          text-shadow: none;
          font-size: var(--emu-semantic-font-sizes-wide-xxl);
        }

        @include mq('x-large') {
          font-size: 130px;
          line-height: 70px;
        }

        br {
          display: none;

          @include mq('large') {
            display: block;
          }
        }

        i {
          font-style: normal;
          font-size: var(--emu-semantic-font-sizes-narrow-xl);
          font-family: var(--emu-semantic-font-families-body);
          position: relative;
          top: -5px;
          left: 4px;

          @include mq('large') {
            top: -8px;
            font-size: 39px;
          }

          @include mq('x-large') {
            top: -38px;
          }
        }

        &::after {
          display: none;
        }

        b {
          text-decoration: none;
          font-weight: var(--emu-common-font-weight-light);

          @include mq('large') {
            display: none !important; // important is needed to make sure that the inline styles are not overriding the styles
          }
        }
      }

      &__description {
        margin-top: 42px;

        @include mq('medium') {
          width: 50%;
        }

        @include mq('large') {
          margin: var(--emu-common-spacing-none);
          margin-top: 100px;
          width: 40%;
          text-align: left;
        }

        @include mq('x-large') {
          margin-top: 250px;
          max-width: 365px; // needed to match design
        }

        p {
          margin-bottom: var(--emu-common-spacing-none);
          font: var(--emu-semantic-typography-narrow-headings-q-a-headline);
          color: var(--emu-semantic-colors-primary-black);
          line-height: 1.5;

          @include mq('large') {
            margin: var(--emu-common-spacing-none);
            font-size: var(--emu-semantic-font-sizes-narrow-xs);
            color: var(--emu-semantic-colors-primary-white-solid);
            line-height: 32px;
          }

          @include mq('x-large') {
            font-size: var(--emu-semantic-font-sizes-narrow-medium);
          }

          b {
            line-break: anywhere;
            display: inline-block;
          }
        }
      }
    }

    .aaaem-teaser__action-container {
      margin-top: 34px;
      background-color: var(--emu-semantic-colors-primary-teal-500);
      border-radius: var(--emu-semantic-border-radius-xl);
      width: 70%;
      transition: all 0.3s ease-out;
      margin-left: auto;
      margin-right: auto;

      @include mq('medium') {
        width: auto;
      }

      @include mq('large') {
        align-self: flex-start;
        margin-top: 40px;
        margin-left: var(--emu-common-spacing-none);
        margin-bottom: 20px;
      }

      a {
        display: flex;
        justify-content: center;
        text-decoration: none;
        font-family: var(--emu-semantic-font-families-heading);
        color: var(--emu-semantic-colors-primary-white-solid);
        font-size: var(--emu-semantic-font-sizes-narrow-small);
        padding: 11px;

        @include mq('medium') {
          padding-left: 30px;
          padding-right: 30px;
          padding-top: var(--emu-common-spacing-small);
          padding-bottom: var(--emu-common-spacing-small);
          letter-spacing: 0.3px;
        }

        @include mq('large') {
          font-size: var(--emu-semantic-font-sizes-narrow-large);
        }
      }

      &:hover,
      &:focus {
        background-color: var(--emu-semantic-colors-primary-white-solid);
        a {
          color: var(--emu-semantic-colors-primary-teal-500);
        }
      }

      &:active {
        background-color: var(--emu-semantic-colors-primary-teal-500);
        a {
          color: var(--emu-semantic-colors-primary-white-solid);
        }
      }
    }

    .disclaimer {
      margin: var(--emu-common-spacing-none);
      padding-top: 40px;
      text-align: center;
      padding-bottom: 40px;

      @include mq('medium') {
        margin-top: var(--emu-common-spacing-none);
        margin-bottom: var(--emu-common-spacing-none);
        margin-left: auto;
        margin-right: auto;
        width: 68%;
      }

      @include mq('large') {
        visibility: visible; // to overwrite default teaser styles
        margin-left: var(--emu-common-spacing-none);
        margin-right: var(--emu-common-spacing-none);
        padding-top: 25px;
        padding-bottom: 18px;
        text-align: left;
        color: var(--emu-semantic-colors-primary-white-solid);
        width: 485px; // value needed to match the design
      }

      p {
        margin-top: var(--emu-common-spacing-none);
        margin-bottom: var(--emu-common-spacing-none);
        margin-left: auto;
        margin-right: auto;
        font-weight: var(--emu-common-font-weight-bold);
        font-family: var(--emu-semantic-font-families-heading);
        letter-spacing: 0.165px; // value from design

        @include mq('large') {
          font-weight: var(--emu-common-font-weight-regular);
        }

        &:first-child {
          @include mq('large') {
            text-indent: -7px;
          }
        }

        &:nth-child(2) {
          padding-top: 5px;
        }

        @include mq('large') {
          margin-left: var(--emu-common-spacing-none);
          margin-right: var(--emu-common-spacing-none);
          font-size: var(--emu-semantic-font-sizes-wide-xs);
          line-height: 16px;
          font-family: var(--emu-semantic-font-families-body);
        }
      }
    }
  }

  .droplet-mobile {
    display: block;
    position: absolute;
    top: 360px;
    right: 34px;

    @include mq('large') {
      display: none;
    }
  }

  .droplet-desktop {
    display: none;

    @include mq('large') {
      display: block;
      position: absolute;
      top: 130px;
      right: 30px;

      img {
        width: 65%;
        margin-left: auto;
      }
    }
  }

  .ready-to-glow-wrapper {
    background: radial-gradient(
      88.3% 79.68% at 55.06% 100%,
      #d9f0f0 0%,
      #fdf6f2 100%,
      #fdf6f2 100%
    );

    @include mq('large') {
      background: radial-gradient(
        184.7% 134.71% at 48.68% 120.94%,
        #d9f0f0 0%,
        #fdf6f2 100%
      );
    }

    .ready-to-glow {
      background-color: transparent;
      padding-top: 61px;
      padding-bottom: var(--emu-common-spacing-xl);
      padding-left: 26px;
      padding-right: 26px;
      text-align: center;

      display: flex;
      justify-content: center;

      @include mq('large') {
        padding-left: 70px;
        padding-right: 70px;
        text-align: left;
      }

      @include mq('x-large') {
        padding-top: 116px;
        padding-bottom: 60px;
        padding-left: 106px;
        padding-right: 130px;
      }

      > div {
        height: 100%;
        position: relative;
        max-width: 1200px;
        display: flex;
        align-items: center;
        flex-direction: column;

        @include mq('large') {
          flex-direction: row;

          div:nth-child(1) {
            flex-basis: 33%;
          }

          div:nth-child(2) {
            flex-basis: 66%;
          }
        }
      }
    }

    .ready-to-glow__image {
      img {
        border: var(--emu-common-border-width-thin);
        border-radius: 360px;

        margin-left: auto;
        margin-right: auto;
        height: 320px;

        @include mq('large') {
          height: auto;
          margin-left: var(--emu-common-spacing-none);
          margin-right: var(--emu-common-spacing-none);
        }
      }
    }

    .ready-to-glow__text {
      padding-top: 24px;

      @include mq('large') {
        padding-left: 47px;
        padding-top: var(--emu-common-spacing-none);
      }

      p {
        font-size: var(--emu-semantic-font-sizes-narrow-large);
        line-height: var(--emu-semantic-line-heights-narrow-large);
        margin: var(--emu-common-spacing-none);
        margin-top: 40px;

        @include mq('large') {
          margin-top: var(--emu-common-spacing-none);
          margin-bottom: 40px;
          font-size: var(--emu-semantic-font-sizes-narrow-xxl);
          line-height: var(--emu-semantic-line-heights-narrow-xxl);
        }

        @include mq('x-large') {
          font-size: var(--emu-semantic-font-sizes-narrow-xxxl);
          line-height: var(--emu-semantic-line-heights-narrow-xxxl);
        }

        sup {
          font-size: 50%;
          line-height: 0;
        }
      }
    }
  }

  .effects-of-treatment-inner ,
  .light-bg {
    text-align: center;
    padding-bottom: 80px;
    background-color: var(--emu-semantic-colors-primary-white-solid);
    padding-top: var(--emu-common-spacing-none);

    @include mq('large') {
      padding-left: 70px;
      padding-right: 70px;
      padding-top: 81px;
      padding-bottom: 95px;
      margin-bottom: var(--emu-common-spacing-none);
    }

    .aem-Grid {
      height: 100%;
      @include mq('large') {
        display: flex;
      }
    }

    .container {
      margin-top: 70px;

      .aaaem-container {
        position: relative;
        height: 100%;
        margin: var(--emu-common-spacing-none);

        .aem-Grid {
          @include mq('large') {
            flex-direction: column;
          }
        }
      }
      .cmp-image {
        img {
          display: inline;
        }
        @include mq('large') {
          min-height: 125px;
        }
        svg {
          width: 175px;
          height: 117px;
        }
      }
      .text {
        .cmp-text {
          width: 87%; // to match figma
          margin: auto;
          h2 {
            font: var(--emu-semantic-typography-narrow-headings-headline-h4);
          }
          p {
            font: var(--emu-semantic-typography-narrow-body-intro-small);
            padding-bottom: var(--emu-common-spacing-small);

            @include mq('large') {
              padding-bottom: 20px;
            }
          }
          @include mq('large') {
            width: 100%;
            margin: var(--emu-common-spacing-none);
          }
        }
      }
      @include mq('large') {
        max-width: 350px;
        margin-bottom: var(--emu-common-spacing-none);
        margin-left: auto;
        margin-right: auto;
        margin-top: 20px;
      }
    }

    .button {
      margin-top: auto;
      margin-bottom: 5px;
      display: flex;
      justify-content: center;

      a {
        color: var(--emu-semantic-colors-primary-white-solid);
        width: auto;
        transition: all 0.3s ease-out;
        min-width: 255px;

        &:hover,
        &:focus {
          color: var(--emu-semantic-colors-primary-teal-300);
        }

        &:active {
          outline: none;
          color: var(--emu-common-colors-white);
        }

        @include mq('large') {
          min-width: 270px;
        }
      }
    }
  }

  .dark-bg {
    background-color: var(--emu-semantic-colors-primary-teal-300);
    h2, 
    p {
      color: var(--emu-common-colors-white);
      sup {
        font-size: 50%;
        line-height: 0;
      }
    }
  }

  .fap-section {
    background-color: var(--emu-semantic-colors-primary-bone);
    padding-top: 41px;
    padding-bottom: 60px;
    padding-left: 25px;
    padding-right: 25px;
    margin-bottom: 100px;

    @include mq('large') {
      padding-top: 96px;
      padding-bottom: 96px;
      margin-bottom: 136px;
    }

    .button {
      justify-content: center;
    }

    &__title {
      padding-bottom: 35px;

      @include mq('large') {
        padding-bottom: 40px;
      }

      h1 {
        margin: var(--emu-common-spacing-none);

        sup {
          font-size: 50%;
          line-height: 0;
          position: relative;
          font-size: var(--emu-semantic-font-sizes-narrow-small);
        }
      }
    }

    &__text {
      padding-bottom: 10px;

      p {
        margin: var(--emu-common-spacing-none);
        font-size: var(--emu-semantic-font-sizes-narrow-small);
        line-height: var(--emu-semantic-line-heights-narrow-medium);

        @include mq('large') {
          font-size: var(--emu-semantic-font-sizes-narrow-large);
          line-height: var(--emu-semantic-line-heights-narrow-large);
          margin-bottom: 10px;
        }
      }
    }

    &__cta {
      width: fit-content;
      @include mq('large') {
        min-width: 298px; // value from design
        justify-content: center;
      }

      span {
        span {
          font-size: var(--emu-semantic-font-sizes-narrow-xs);
          line-height: var(--emu-semantic-line-heights-narrow-small);

          @include mq('large') {
            font-size: var(--emu-semantic-font-sizes-narrow-large);
          }
        }
      }
    }
  }

  .effects-of-treatment {
    padding-bottom: 32px;
    @include mq('large') {
      padding-bottom: 70px;
    }
    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      > .text {
        margin: auto;
        width: 87%;
        @include mq('large') {
          margin: var(--emu-common-spacing-none);
          padding-left: 70px;
          padding-right: 70px;
          width: 100%;
        }
        p {
          font-size: var(--emu-semantic-font-sizes-wide-xs);
          margin-top: 0;
        }
      }
    }
  }
  
  .effects-of-treatment-inner {
    background-color: inherit;
    padding-bottom: 28px;
    padding-top: 0;
    @include mq('large') {
      padding-bottom: 40px;
      padding-top: 50px;
    }
    .container {
      margin-top: 0;
      .aaaem-container {
        ::after {
          background-color: var(--emu-semantic-colors-primary-black);
          content: '';
          height: 2px;
          margin: auto;
          width: 40%;
          @include mq('large') {
            display: none;
          }
        }
      }
      &:not(:first-of-type) {
        margin-top: 0;
      }
      &:not(:last-of-type) {
        .aaaem-container {
          @include mq('large') {
            border-right: 2px solid var(--emu-semantic-colors-primary-black);
          }
        }
      }
      .aaaem-container {
        margin-top: 0;
        @include mq('large') {
          padding-left: 16px;
          padding-right: 16px;
        }
      }
    }
    > text {
      font-size: var(--emu-semantic-font-sizes-wide-xs);
    }

    .image {
      align-content: center;
      display: grid;
      justify-content: center;
      margin: auto;
      padding-bottom: 65%;
      position: relative;
      width: 65%;
      @include mq('large') {
        padding-bottom: 90%;
        width: 90%;
      }
    }
    
    .aaaem-image {
      background-color: var(--emu-common-colors-white);
      border-radius: 50%;
      bottom: 0;
      display: block;
      position: absolute;
      top: 0;
      width: 100%;
    }
    
    img {
      padding: 30px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      width: 75%;
      max-height: 100%;
      @include mq('large') {
        width: 100%;
      }
    }
  }
}
