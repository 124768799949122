.embed {
  .cmp-embed {
    margin: auto;
    width: 90%;
    height: 100%;
    max-width: 605px;
  }

  iframe {
    width: 100%;
    height: 100%;
    border-radius: var(--emu-semantic-border-radius-large);
  }

  @include mq('medium') {
    .cmp-embed {
      width: 70%;
    }
  }

  @include mq('x-large') {
    .cmp-embed {
      width: 100%;
    }
  }
}
