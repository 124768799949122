.has-media {
  @include mq('large') {
    height: 810px;
  }
}

.teaser {
  .teaser-container {
    align-self: center;
    @include mq('large') {
      display: flex;
      height: 100%;
    }
  }

  .teaser-image-container {
    .cmp-teaser__image--desktop {
      img {
        object-fit: cover;
      }
    }
  }

  .content-container {
    padding-top: var(--emu-common-spacing-none);
    width: 100%;

    .content {
      margin: var(--emu-common-spacing-none);
      order: 2;

      @include mq('large') {
        order: 1;
      }
    }

    .title-lockup {
      margin: auto;

      @include mq('large') {
        margin-left: var(--emu-common-spacing-none);
      }

      h2 {
        margin-top: var(--emu-common-spacing-none);
        margin-bottom: var(--emu-common-spacing-none);
      }
    }

    .disclaimer {
      margin-bottom: 42px;
      order: 1;

      @include mq('large') {
        visibility: hidden;
      }

      p {
        font: var(--emu-semantic-typography-narrow-body-footnotes);
        @include mq('large') {
          font: var(--emu-semantic-typography-wide-body-footnotes);
          margin-bottom: var(--emu-common-spacing-none);
        }
      }

      @include mq('large') {
        order: 2;
        margin-top: var(--emu-common-spacing-none);
        margin-bottom: var(--emu-common-spacing-none);
      }
    }

    @include mq('large') {
      justify-content: space-between;
      height: 100%;
      position: relative;
      width: 100%;
      margin-left: 95px;
    }
  }

  .cmp-teaser {
    position: relative;
    overflow: hidden;

    img {
      object-position: 80%;
      object-fit: cover;
      width: 100%;
      height: 400px;

      @include mq('large') {
        left: var(--emu-common-spacing-none);
        position: absolute;
        top: var(--emu-common-spacing-none);
        height: 100%;
      }
    }

    &__title,
    &__description {
      text-align: center;

      @include mq('large') {
        text-align: left;
      }
    }

    &__title {
      font: var(--emu-semantic-typography-narrow-headings-eyebrow);
      margin-bottom: 42px;

      @include mq('large') {
        font: var(--emu-semantic-typography-wide-headings-eyebrow);
        margin: var(--emu-common-spacing-none);
      }

      &::after {
        content: '';
        display: flex;
        position: relative;
        width: 100%;
        background: var(--emu-semantic-colors-primary-teal-300);
        height: var(--emu-semantic-border-width-thick);
        border-radius: var(--emu-semantic-border-radius-round);
      }
    }

    &__description {
      margin: auto;

      p {
        font: var(--emu-semantic-typography-narrow-headings-headline-h1);

        sup {
          position: relative;
          font-size: 50%;
          line-height: 0;
        }
      }

      @include mq('large') {
        margin: var(--emu-common-spacing-none);

        p {
          margin-top: var(--emu-common-spacing-medium);
          font: var(--emu-semantic-typography-wide-headings-headline-h1);
        }
      }
    }
  }

  @include mq('large') {
    .teaser-container {
      position: relative;
    }
  }
}
