.button {
  &:not(.backToTop) {
    display: flex;
    a {
      text-decoration: none;
      text-align: center;
      width: 100%;
      span {
        margin: auto;
      }
    }
    button {
      display: flex;
    }
  }
}
