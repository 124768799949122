#sitemap {
  .sitemap-text {
    padding-top: 90px;
    text-align: center;

    @include mq('large') {
      padding-top: 150px;
      text-align: left;
      margin-left: 95px;
    }

    h1 {
      display: inline-block;
      margin-bottom: 45px;
      font: var(--emu-semantic-typography-narrow-headings-eyebrow);

      @include mq('large') {
        font: var(--emu-semantic-typography-wide-headings-eyebrow);
      }

      &::after {
        content: '';
        width: 100%;
        background: var(--emu-semantic-colors-primary-teal-300);
        height: var(--emu-semantic-border-width-thick);
        border-radius: var(--emu-semantic-border-radius-round);
        display: flex;
        position: relative;
      }
    }

    ul {
      margin-left: 30px;

      @include mq('large') {
        margin-left: var(--emu-common-spacing-none);
        padding-left: 23px;
      }
    }

    li {
      text-align: left;
    }

    a {
      &,
      &:hover,
      &:active,
      &:focus {
        color: var(--emu-semantic-colors-primary-teal-500);
      }
    }
  }
}
