/* DISCLAIMER TEXT AFTER TEASER START */

.disclaimer--text--desktop {
  display: none;

  @include mq('large') {
    display: block;
    padding-top: 10px;
    padding-left: 95px;

    p {
      margin: var(--emu-common-spacing-none);
      font-size: var(--emu-semantic-font-sizes-wide-xs);
      line-height: var(--emu-semantic-line-heights-wide-small);
    }
  }
}

/* DISCLAIMER TEXT AFTER TEASER END */
